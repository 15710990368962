import { useCitiesList } from '../../swr/admin'

export default function SelectCity({ value = '', onSelect }) {

	const { data, isLoading, isError } = useCitiesList()

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const governorates = [...new Set(data.map(item => item.governorate))]; // [ 'A', 'B']


	return (
		<div className="select is-fullwidth">
			<select value={value} onChange={(e) => onSelect(e.target.value)} name="city">
				<option value="">Select a city</option>
				{governorates.map(text => (
					<optgroup label={text} key={text}>
						{data.filter(item => item.governorate === text).map(city => (
							<option key={city.name} value={city.name}>{city.name}</option>
						))}
					</optgroup>
				))}
			</select>
		</div>
	)
}