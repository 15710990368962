import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as PricingPlanAPI from '../../../utils/api/pricingPlan';
import * as DeliveryCompanyAPI from '../../../utils/api/deliveryCompany';
import * as UserAPI from '../../../utils/api/user';

export default function* watcherSaga() {
    yield takeEvery(actionTypes.GET_PRICING_PLANS, getPricingPlansWorker);
    yield takeEvery(actionTypes.GET_DELIVERY_COMPANIES, getDeliveryCompaniesWorker);
    yield takeEvery(actionTypes.UPDATE_USER_PACKAGE_SELECTION, updateUserPackageSelectionWorker);
}

function* getPricingPlansWorker(action) {
    const { deliveryCompanyId } = action.payload;
    yield put(actions.getPricingPlansStart());
    try {
        const { data } = yield call(PricingPlanAPI.getPricingPlans, deliveryCompanyId);
        yield put(actions.getPricingPlansSuccess({ data }));
    } catch (error) {
        yield put(actions.getPricingPlansFail());
    }
}

function* getDeliveryCompaniesWorker(action) {
    yield put(actions.getDeliveryCompaniesStart());
    try {
        const { data } = yield call(DeliveryCompanyAPI.getDeliveryCompanies);
        yield put(actions.getDeliveryCompaniesSuccess({ data }));
    } catch (error) {
        yield put(actions.getDeliveryCompaniesFail());
    }
}

function* updateUserPackageSelectionWorker(action) {
    const { payload, userId } = action.payload;
    console.log("payload is: ", payload);
    console.log("userId is: ", userId);
    yield put(actions.updateUserPackageSelectionStart());
    try {
        const { data } = yield call(UserAPI.updateUserPackageSelection, userId, payload);
        yield put(actions.updateUserPackageSelectionSuccess({ data }));
        toast.success('Package plan is selected successfully');
    } catch (error) {
        yield put(actions.updateUserPackageSelectionFail());
    }
}