import { makeHttpActionTypes } from '../../../utils/reduxHelpers';

const REDUCER_NAME = 'PACKAGE_SELECTION';

export const { GET_DELIVERY_COMPANIES, GET_DELIVERY_COMPANIES_START, GET_DELIVERY_COMPANIES_SUCCESS, GET_DELIVERY_COMPANIES_FAIL } = makeHttpActionTypes(
    REDUCER_NAME,
    'GET_DELIVERY_COMPANIES'
);

export const { GET_PRICING_PLANS, GET_PRICING_PLANS_START, GET_PRICING_PLANS_SUCCESS, GET_PRICING_PLANS_FAIL } = makeHttpActionTypes(
    REDUCER_NAME,
    'GET_PRICING_PLANS'
);

export const { UPDATE_USER_PACKAGE_SELECTION, UPDATE_USER_PACKAGE_SELECTION_START, UPDATE_USER_PACKAGE_SELECTION_SUCCESS, UPDATE_USER_PACKAGE_SELECTION_FAIL } =
    makeHttpActionTypes(REDUCER_NAME, 'UPDATE_USER_PACKAGE_SELECTION');