import AdminFooterNavigation from '../../components/AdminFooterNavigation'
import DeliveryZones from '../../components/DeliveryZones'
import Cities from '../../components/Cities'

export default function Fees({ }) {
	return(
		<div className="container">
			<h1 className="is-size-1 has-text-centered my-5">Delivery Zones</h1>
			<AdminFooterNavigation activeTab={'admin-fees'} />
			<DeliveryZones />
			<hr />
			<Cities />
		</div>
	)
}