export const CAPITAL_GOVERNORATE = {
	/* CAPITAL GOVERNORATE */
	ABDULLA_AL_SALEM: 'Abdulla Al-Salem',
	ADAILIYA: 'Adailiya',
	BNAID_AL_QAR: 'Bnaid Al-Qar',
	DAIYA: 'Daʿiya',
	DASMA: 'Dasma',
	DOHA: 'Doha',
	DOHA_PORT: 'Doha Port',
	FAIHA: 'Faiha\'',
	FAILAKA: 'Failaka',
	GRANADA: 'Granada (Kuwait)',
	// JABER_AL_AHMAD_CITY: 'Jaber Al-Ahmad City', RED
	JIBLA: 'Jibla',
	KAIFAN: 'Kaifan',
	KHALDIYA: 'Khaldiya',
	MANSURIYA: 'Mansūriya',
	MIRGAB: 'Mirgab',
	// NAHDHA: 'Nahdha',
	// NORTH_WEST_SULAIBIKHAT: 'North West Sulaibikhat',
	NUZHA: 'Nuzha',
	QADSIYA: 'Qadsiya',
	QURTUBA: 'Qurtuba',
	RAWDA: 'Rawda',
	SHAMIYA: 'Shamiya',
	SHARQ: 'Sharq',
	SHUWAIKH: 'Shuwaikh',
	SHUWAIKH_INDUSTRIAL_AREA: 'Shuwaikh Industrial Area',
	SHUWAIKH_PORT: 'Shuwaikh Port',
	SULAIBIKHAT: 'Sulaibikhat',
	SURRA: 'Surra',
	UMM_AN_NAMIL_ISLAND: 'Umm an Namil Island',
	YARMOUK: 'Yarmouk',
}

export const HAWALLI_GOVERNORATE = {
	/* HAWALLI GOVERNORATE */
	// ANJAFA: 'Anjafa',
	BAYAN: 'Bayān',
	// BIDA: 'Bi\'da',
	HAWALLY: 'Hawally',
	HITTIN: 'Hittin',
	JABRIYA: 'Jabriya',
	MADIAN_HAWALLI: 'Maidan Hawalli',
	MISHRIF: 'Mishrif',
	// MUBARAK_AL_JABIR: 'Mubarak Al-Jabir',
	// NIGRA: 'Nigra',
	RUMAITHIYA: 'Rumaithiya',
	// SALAM: 'Salam',
	SALMIYA: 'Salmiya',
	SALWA: 'Salwa',
	// SHAAB: 'Sha\'ab',
	SHUHADA: 'Shuhada',
	// SIDDIQ: 'Siddiq',
	// SOUTH_SURRA: 'South Surra',
	ZAHRA: 'Zahra',
}

export const MUBARAK_AL_KABEER_GOVERNORATE = {
	/* MUBARAK AL_KABEER GOVERNORATE */
	// ABU_AL_HASANIYA: 'Abu Al Hasaniya',
	ABU_FUTAIRA: 'Abu Futaira',
	ADAN: 'Adān',
	AL_QURAIN: 'Al Qurain',
	AL_QUSOUR: 'Al-Qusour',
	FINTAS: 'Fintās',
	FUNTAITIS: 'Funaitīs',
	MISILA: 'Misīla',
	MUBARAK_AL_KABEER: 'Mubarak Al-Kabeer',
	SABAH_AL_SALEM: 'Sabah Al-Salem',
	// SABHAN: 'Sabhān',
	// SOUTH_WISTA: 'South Wista',
	// WISTA: 'Wista',
}

export const AHMADI_GOVERNORATE = {
	/* AHMADI GOVERNORATE */
	ABU_HALIFA: 'Abu Halifa',
	AHMADI: 'Ahmadi',
	DHAHER: 'Dhaher',
	FAHAHEEL: 'Fahaheel',
	MANGAF: 'Mangaf',
	RIQQA: 'Riqqa',
	SABAH_AL_AHMAD_SEA_CITY: 'Sabah Al Ahmad Sea City',
	WAFRA: 'Wafra',
}

export const FARWANIYA_GOVERNORATE = {
	/* Farwaniya Governorate */
	ANDALOUS: 'Andalous',
	ARDIYA: 'Ardiya',
	FARWANIYA: 'Farwaniya',
	JLEEB_AL_SHUYOUKH: 'Jleeb Al-Shuyoukh',
	KHAITAN: 'Khaitan',
	RAI: 'Rai',
	AL_RIGGAE: 'Al-Riggae',
}

export const JAHRA_GOVERNORATE = {
	/* JAHRA Governorate */
	JAHRA: 'Jahra',
	SUBIYA: 'Subiya',
	SULAIBIYA: 'Sulaibiya',
}