import { useAddress } from '../../swr/index'

export default function PickupAddress({ addressId, field }) {

	const { address, isLoading, isError } = useAddress(addressId) 

	if (isLoading) return 'Loading...'
	if (isError) return "Error..."

	return(	
		<span>
			{field === 'place_name' && <span>{address.place_name}</span>}
			{field === 'place_address' && <span>{address.place_address}</span>}
		</span>
	)
}