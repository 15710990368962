
import { useDeliveryCompanies } from '../swr/admin'
import { useState } from 'react'
import { API_URL, POST_FETCH_OPTIONS } from '../common/constants'
import { useSWRConfig } from 'swr'
import { Link } from 'react-router-dom'

export default function DeliveryCompanies() {

	const { mutate } = useSWRConfig()
	const { data, isLoading, isError } = useDeliveryCompanies()
	const [addingCompany, setAddingCompany] = useState(false)
	const [companyName, setCompanyName] = useState('')
	const [companyEmail, setCompanyEmail] = useState('')
	const [companyPhoneNumber, setCompanyPhoneNumber] = useState('')

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const addCompany = (e) => {
		e.preventDefault();

		if (!companyName) {
			return
		}

		mutate(API_URL.DELIVERY_COMPANY, async data => {
			const newCompany = await fetch(API_URL.DELIVERY_COMPANY, POST_FETCH_OPTIONS({
				name: companyName,
				email: companyEmail,
				phone_number: companyPhoneNumber
			})).then(res => res.json())

			setCompanyName('')
			setCompanyEmail('')
			setCompanyPhoneNumber('')
			setAddingCompany(false)
			return [...data, newCompany]
		})
	}

	return (
		<div>
			<div className={`modal ${addingCompany ? 'is-active' : ''}`}>
				<div className="modal-background"></div>
				<div className="modal-content">
					<div className="box">
						<button onClick={(e) => {e.preventDefault(); setAddingCompany(false) } } className="is-pulled-right is-large is-clickable" aria-label="close">X</button>
						<h2 className="is-size-3 mb-2">Add Delivery Company</h2>
						<form onSubmit={addCompany}>
							<label className="label">Name</label>
							<input 
								onChange={(e) => setCompanyName(e.target?.value)} 
								className="input mb-3" 
								value={companyName} 
								placeholder="Delivery Company Name" 
								type="text" 
								name="name" 
							/>

							<label className="label">Email</label>
							<input 
								onChange={(e) => setCompanyEmail(e.target?.value)} 
								className="input mb-3" 
								value={companyEmail} 
								placeholder="Delivery Company Email" 
								type="email" 
								name="email" 
							/>

							<label className="label">Phone Number</label>
							<div className="is-flex is-flex-direction-row">
								<input 
									onChange={(e) => setCompanyPhoneNumber(e.target?.value)} 
									className="input" 
									value={companyPhoneNumber} 
									placeholder="Delivery Company Phone Number" 
									type="text" 
									name="name" 
								/>
								<input type="submit" className="button-width ml-4 is-info button" name="Save Company" />
							</div>
			
						</form>
					</div>
					<button onClick={() => setAddingCompany(false)} className="modal-close is-large" aria-label="close"></button>
				</div>
			</div>
			<button 
				onClick={() => setAddingCompany(true)}  
				className="button is-info is-pulled-right"
			>
				Add Delivery Company +
			</button>
			<br />
			<br />

			<table className="table is-bordered is-striped is-fullwidth is-centered">
				<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>Phone Number</th>
					</tr>
				</thead>
				<tbody>
					{data.map(company => 
						<tr key={company.name}>
						  <td>{company.name}</td>
						  <td>{company.email}</td>
						  <td>{company.phone_number}</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}