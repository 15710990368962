import { DELIVERY_STATUS } from '../../common/constants'

export default function StatusPicker({ selected, setStatus, isButtons }) {

	if (isButtons) {
		return(
			<div>
					<button 
						onClick={() => setStatus(null)} 
						className={`button ${selected === null ? 'is-info' : ''}`}
					>
						All
					</button>
				{Object.keys(DELIVERY_STATUS).map(key => 
					<button 
						key={key}
						onClick={() => setStatus(DELIVERY_STATUS[key])} 
						className={`button ${selected === DELIVERY_STATUS[key] ? 'is-info' : ''}`}
					>
						{DELIVERY_STATUS[key]}
					</button>
				)}
			</div>
		)
	} else {
		return(
			<div>
				<select value={selected} onChange={(e) => setStatus(e.target?.value)}>
					<option value=''>All</option>
					{Object.keys(DELIVERY_STATUS).map(key => 
						<option key={key} value={DELIVERY_STATUS[key]}>{DELIVERY_STATUS[key]}</option>
					)}
				</select>
			</div>
		)
	}

}