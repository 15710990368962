import { useState, useRef, useEffect } from 'react'
import FooterNavigation from '../components/FooterNavigation'
import { API_URL, POST_FETCH_OPTIONS, DELETE_FETCH_OPTIONS } from '../common/constants'
import { useSWRConfig } from 'swr'
import { useProductItems, usePickupAddresses, usePickupAddressesTotal } from '../swr/index'
import AddressAutoComplete from '../components/AddressAutoComplete'
import SelectCity from '../components/small/SelectCity'
import Pagination from '../components/small/Pagination'
import GoogleMapReact from 'google-map-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddProductsPage() {

	const { mutate } = useSWRConfig()
	const { products, isLoading, isError } = useProductItems()
	const { total } = usePickupAddressesTotal()
	console.log(products)

	const limit = 5;

	const searchBar = useRef(null)

	const [addingAddress, setAddingAddress] = useState(false)

	const [addingProduct, setAddingProduct] = useState(false)
	const [timer, setTimer] = useState()
	const [showPredictions, setShowPredictions] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)

	const [selectedPlace, setSelectedPlace] = useState({
		description: '',
		place_id: '',
	})

	const [pickupCity, setPickupCity] = useState('')
	const [additionalInformation, setAdditionalInformation] = useState('')

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const showProductModal = () => {
		setAddingProduct(true)
	}

	const handleAddProduct = (e) => {
		e.preventDefault()
		const formData = new FormData(e.target);
		const keys = [
			'name',
			'description',
			'price',
		]

		let data = {}
		keys.forEach(key => data[key] = formData.get(key))
		data.price = parseFloat(data.price)

		if (!data.price || !data.description || !data.name) {
			toast.error('Please fill out all fields.')
			return
		}

		mutate(API_URL.PRODUCTS, async existing => {
			const newItem = await fetch(API_URL.PRODUCTS, POST_FETCH_OPTIONS(data)).then(res => res.json())
			setAddingProduct(false)
			e.target.reset()
			return [...existing, newItem]
		})
	}

	const deleteAddress = async (address) => {
		await fetch(`${API_URL.PICKUP_ADDRESSES}/${address.id}`, DELETE_FETCH_OPTIONS())
		mutate([API_URL.PICKUP_ADDRESSES, `?page=${currentPage}&limit=${limit}`])
		mutate(API_URL.PICKUP_ADDRESSES_TOTAL)
	}

	const PickupAddresses = ({ page = 1, limit = 5 }) => {
		const { pickupAddresses, isLoading, isError } = usePickupAddresses(page, limit)
		
		const [showMap, setShowMap] = useState([])
		const [hoverDistance, setHoverDistance] = useState(10)
		const [activeMapId, setActiveMapId] = useState(null)
		const [markedLat, setMarkedLat] = useState(0)
		const [markedLng, setMarkedLng] = useState(0)
		const [name, setName] = useState('')
		const [overlay, setOverlay] = useState(false)
		const [addAdditional, setAddAdditional] = useState(false)
		const [additionalInformation, setAdditionalInformation] = useState('')

		if (isLoading) { return 'Loading...' }
		if (isError) { return 'Error...' }

		const handleApiLoaded = (map, maps) => {
		  // use map and maps objects
		  console.log(map)
		};

		const checkMap = (address, index) => {
			console.log(address)
			setAddAdditional(false)
			setMarkedLat(address.place_lat)
			setMarkedLng(address.place_lng)
			setActiveMapId(address.id)
			if (showMap[index]) {
				const newArray = new Array(pickupAddresses.length).fill(false)
				setShowMap(newArray)
				return 
			}
			const newArray = new Array(pickupAddresses.length).fill(false)
			
			newArray[index] = true
			setShowMap(newArray)
		}

		const addInfo = (address) => {
			console.log(address)
			const newArray = new Array(pickupAddresses.length).fill(false)
			setShowMap(newArray)

			setName(address.place_name || address.place_address)
			setAdditionalInformation(address.additional_information || '')
			setActiveMapId(address.id)

			setAddAdditional(true)


			if (address.id === activeMapId) {
				setAddAdditional(false)
			}
		}

		const editLocation = async () => {
			// console.log(address)
			const newAddress = await fetch(`${API_URL.PICKUP_ADDRESSES}/${activeMapId}`, POST_FETCH_OPTIONS({ place_lat: markedLat, place_lng: markedLng })).then(res => res.json())
			console.log(newAddress)
			if (newAddress.length) {
				mutate([API_URL.PICKUP_ADDRESSES, `?page=${currentPage}&limit=${limit}`])
				toast.info('Updated place location!')
				setOverlay(false)
				setShowMap([])
			}
		}
		const saveInfo = async () => {
			const newAddress = await fetch(`${API_URL.PICKUP_ADDRESSES}/${activeMapId}`, POST_FETCH_OPTIONS({ additional_information: additionalInformation })).then(res => res.json())
			console.log(newAddress)
			if (newAddress.length) {
				mutate([API_URL.PICKUP_ADDRESSES, `?page=${currentPage}&limit=${limit}`])
				toast.info('Updated additional delivery instructions!')
				setAddAdditional(false)
				setAdditionalInformation('')
			}
		}

		const Marker = (props) => {
			// console.log(props)
			if (props.$hover) {
				setHoverDistance(70)
			} else {
				setHoverDistance(10)
			}
			return (
			
				<div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
					<img width="10" height="10" src="/red-circle.svg" alt="delivery status icon" />
					{(props.$hover && !overlay) &&
						<div className="box">
							<button onClick={() => setOverlay(true)} className="button is-info">Edit address location?</button>
						</div>
					}
					{(props.$hover && overlay) &&
						<div className="box">
							<button onClick={() => editLocation()} className="button is-info">Confirm new address location?</button>
						</div>
					}
				</div>

			)
		}

		const handleMapClick = ({ x, y, lat, lng, event }) => {
			if (overlay) {
				setMarkedLat(lat)
				setMarkedLng(lng)		
			}
		}

		return(
			<div className="box">
				{addAdditional && <div className="mb-6">
					<label>Additional Delivery Instructions for {name}</label>
					<div className="is-flex">
						<input className="input" onChange={(e) => setAdditionalInformation(e.target.value)} value={additionalInformation} name="additional_information" type="text" placeholder="Additional information for address" />
						<button onClick={() => saveInfo()} className="button is-info ml-2 mb-2">Save Info</button>
					</div>
					<hr />
				</div>}
				{
					pickupAddresses.map((address, i) => (
						<div key={address.place_id}>
							<p><span className="is-size-5 has-text-weight-medium">{address.place_name}</span><button onClick={() => deleteAddress(address)} className="is-pulled-right is-clickable">X</button></p>
							<p>{address.place_address}</p>
							<div className="is-flex my-1">
								<p>{address.additional_information}</p>
								<button className="ml-auto" onClick={() => checkMap(address, i)}>check google maps location</button>
							</div>
{/*							<button onClick={() => addInfo(address)}>add additional delivery instructions</button>*/}							{showMap[i] && 
								<div style={{ height: '60vh', width: '100%' }}>
									{overlay && 
										<div style={{ position: 'relative', top: 0, left: 0, zIndex: 20 }}>
											<div style={{ position: 'absolute'}}>Click anywhere to place marker: <a className="" onClick={() => setOverlay(false)}>cancel</a></div>
										</div>
									}
									<GoogleMapReact
									  bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
									  defaultCenter={{lat: 29.3000, lng: 47.982104}}
									  defaultZoom={11}
									  onClick={handleMapClick}
									  hoverDistance={hoverDistance}
									  yesIWantToUseGoogleMapApiInternals
									  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
									>
									  <Marker
									    lat={markedLat}
									    lng={markedLng}
									   />
									</GoogleMapReact>
								</div>
							}

							<hr className={`${i === pickupAddresses.length - 1 ? 'mb-0' : 'mb-4'}`}  />
						</div>
					))
				}
			</div>
		)
	}


	const savePlace = (data) => {
		const format = {
			description: data.description,
			place_id: data.place_id,
			lat: data.lat,
			lng: data.lng
		}
		setSelectedPlace(format)
	}

	const saveAddress = () => {

		const format = {
			description: selectedPlace.description,
			place_id: selectedPlace?.place_id,
			additional_information: additionalInformation,
			city: pickupCity,
			lat: selectedPlace?.lat,
			lng: selectedPlace?.lng
		}

		if (!format.place_id && (!format.lat && !format.lng)) {
			toast.error('Please search for and select an address.')
			return
		} else if (!format.city) {
			toast.error('Please select a city for this address.')
			return
		}

		mutate([API_URL.PICKUP_ADDRESSES, `?page=${currentPage}&limit=${limit}`], async existing => {
			const newAddress = await fetch(API_URL.PICKUP_ADDRESSES, POST_FETCH_OPTIONS(format)).then(res => res.json())

			if (newAddress.message) {
				toast.error(newAddress.message)
				return [...existing]
			}

			setAdditionalInformation('');
			setSelectedPlace({});
			setPickupCity('');
			setAddingAddress(false)

			mutate(API_URL.PICKUP_ADDRESSES_TOTAL, async currentTotal => {
				return currentTotal + 1
			})

			return [newAddress, ...existing]
		})
	
	}

	return(
		<div className="container mb-5">
			<ToastContainer />
			<h1 className="is-size-1 has-text-centered my-5">Add Products / Addresses</h1>
			<FooterNavigation activeTab={'products'} />
			<div className="my-6" />
			<div className="mx-auto max-width-md" onClick={() => setShowPredictions(false)}>
				<div className={`modal ${addingProduct ? 'is-active' : ''}`}>
					<div className="modal-background"></div>
					<div className="modal-content">
						<div className="box">
							<h2 className="is-size-3">Add Product</h2>
							<form onSubmit={handleAddProduct}>
								<label className="label">Product Name</label>
								<input name="name" className="input mb-2" type="text" />

								<label className="label">Product Description</label>
								<textarea name="description" className="textarea mb-2" placeholder="Product description..."></textarea>

								<label className="label">Product Price</label>
								<div className="is-flex is-flex-direction-row">
									<input name="price" className="input" type="number" step="0.001" />
									<input type="submit" className="button-width ml-4 is-info button" name="Save Product" />
								</div>
							</form>
						</div>
					</div>
					<button onClick={() => setAddingProduct(false)} className="modal-close is-large" aria-label="close"></button>
				</div>

				<div className="is-flex mb-2">
					<h2 className="is-size-3">Add Products</h2>
					<button onClick={() => showProductModal()} className="ml-auto button is-info is-align-self-flex-end">Add Product +</button>
				</div>
				<div className="box">
					<table className="table mx-auto is-bordered is-fullwidth">
					  <thead>
					    <tr>
					      <th>Product Name</th>
					      <th>Description</th>
					      <th>Price</th>	   
					    </tr>
					  </thead>
					  <tbody>
							{products.map(product => (
								<tr key={product.id}>
									<td>{product.name}</td>
									<td>{product.description}</td>
									<td>{Math.abs(product.price).toFixed(3)} KWD</td>
								</tr>
							))}		      
					  </tbody>
					</table>
				</div>

				<div className="is-flex is-align-items-center">
					<h2 className="is-size-3">Pickup Addresses</h2>
					<button onClick={() => setAddingAddress(true)} className="ml-auto button is-info">Add Pickup Address +</button>
				</div>

				<div className={`modal ${addingAddress ? 'is-active' : ''}`}>
					<div className="modal-background"></div>
					<div className="modal-content">
						<div className="box">
							<h2 className="is-size-3">Add Pickup Address</h2>
							<AddressAutoComplete callback={savePlace} className="mb-2" placeholder="Search addresses" setShowPredictions={setShowPredictions} showPredictions={showPredictions} />
							<div className="mb-2">
								<SelectCity value={pickupCity} onSelect={(city) => setPickupCity(city)} />
							</div>
							<div className="is-flex">
								<input className="input" onChange={(e) => setAdditionalInformation(e.target.value)} value={additionalInformation} name="additional_information" type="text" placeholder="Additional information for address" />
								<button onClick={() => saveAddress()} className="button is-info ml-2 mb-2">Add Address +</button>
							</div>
						</div>
					</div>
					<button onClick={() => setAddingAddress(false)} className="modal-close is-large" aria-label="close"></button>
				</div>

				<PickupAddresses page={currentPage} limit={limit} />
				<Pagination 
				  currentPage={currentPage} 
				  totalPages={Math.ceil(total / limit)} 
				  setPage={setCurrentPage}
				/>
			</div>
		</div>
	)
}