import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { deletePricingPlan, getPricingPlans, updatePricingPlan } from '../redux/actions';
import { pricingPlanListSelector } from '../redux/selectors';
import storage from '../../../utils/storage';
import PricingPlanOverview from './PricingPlanOverview';

const ListPricingPlan = () => {
    const dispatch = useDispatch();
    const { pricingPlans, getPricingPlansLoading } = useSelector(pricingPlanListSelector, shallowEqual);

    useEffect(() => {
        const deliveryCompanyId = storage.get('deliveryCompanyId');
        dispatch(getPricingPlans({ deliveryCompanyId }));
    }, [dispatch]);

    const updatePricingPlanHandler = (pricingPlanId, payload) => {
        const deliveryCompanyId = storage.get('deliveryCompanyId');
        dispatch(updatePricingPlan({ deliveryCompanyId, pricingPlanId, payload }));
    };

    const deletePricingPlanHandler = (pricingPlanId) => {
        const deliveryCompanyId = storage.get('deliveryCompanyId');
        dispatch(deletePricingPlan({ deliveryCompanyId, pricingPlanId }));
    };

    return (
        <PricingPlanOverview
            getPricingPlansLoading={getPricingPlansLoading}
            pricingPlans={pricingPlans}
            updatePricingPlanHandler={updatePricingPlanHandler}
            deletePricingPlanHandler={deletePricingPlanHandler}
        />
    );
};

export default ListPricingPlan;