import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import OnwayFooter from '../../components/UI/OnwayFooter'

export default function LandingPage() {

	//TODO: check jwt token, redirect to dashboard if valid
	//make sure not admin, force admin to login each time.

	const [searchParams] = useSearchParams();
	const [showExpired, setShowExpired] = useState(false)

	useEffect(() => {
		if (searchParams.get('sessionExpired')) {
			setShowExpired(true)
		}
	}, [])

	return(
		<div>
			<div className="center">
				<div className="max-width-md mx-auto">
					<div className="mx-auto has-text-centered">
						<img width="240px" src={process.env.PUBLIC_URL + '/logo-purple.png'} />
					</div>
					<p className="has-text-centered is-size-4 mb-6">You Sell, We Deliver</p>
					<div className="is-flex is-justify-content-center">
						<Link to="/business"><button style={{"width": '150px'}} className="mr-6 button is-fullwidth has-background-grey-light">Business</button></Link>
						<Link to="/driver"><button className="button is-fullwidth has-background-grey-light mb-4">Delivery Company</button></Link>
					</div>
					{showExpired && <p className="has-text-danger has-text-centered mt-3">Your session has expired. Please login again.</p>}
				</div>
			</div>
			<OnwayFooter />
		</div>
	)
}