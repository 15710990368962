import React, { useEffect, useRef } from 'react';
import styles from '../styles/ConfirmationModal.module.css';
import { Button } from './PricingPlan.common.components';

const ConfirmationModal = ({ isVisible, title, message, onConfirm, onCancel }) => {
  const modalRef = useRef();

  // Close the modal if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onCancel]);

  if (!isVisible) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalBox} ref={modalRef} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeading}>{title}</div>
        <div className={styles.modalContent}>{message}</div>
        <div className={styles.modalActions}>
          <Button
            text="Cancel"
            className="button_grey"
            onClick={onCancel}
          />
          <Button
            text="Delete"
            className="button_red"
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
