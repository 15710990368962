import { useEffect } from 'react'

export default function CheckDriverAuth() {

	useEffect(() => {
		const script = document.createElement('script');
		script.src = "https://js.arcgis.com/3.20/"
		script.async = true
		document.body.appendChild(script)

		const link = document.createElement('link');
		link.type = "text/css";
		link.rel = "stylesheet";
		link.href = "https://js.arcgis.com/3.20/esri/css/esri.css"

		document.head.appendChild(link);

		return () => {
			document.body.removeChild(script);
			document.head.removeChild(link);
		}
	}, [])

	return (
		<div>
	{/*		<script type="text/javascript">

			    require(["esri/map", "esri/layers/ArcGISTiledMapServiceLayer", "dojo/domReady!"], function (Map, ArcGISTiledMapServiceLayer) {

			        var initialExtent = new esri.geometry.Extent
			                      ({ "xmin": 5190124.0769009115, "ymin": 3335306.073392077, "xmax": 5437155.058767752, "ymax": 3495933.660671074, "spatialReference": { "wkid": 102100 } });
			        var paciMap = new Map("map", { extent: initialExtent });

			        var BaseMap = new esri.layers.ArcGISTiledMapServiceLayer("https://kuwaitportal.paci.gov.kw/arcgisportal/rest/services/Hosted/ArabicMap/MapServer/");
			        paciMap.addLayer(BaseMap);
			    });
			</script>*/}

			<div id="map"></div>

			
		</div>
	)
}