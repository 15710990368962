import * as types from './actionTypes';

export const getDeliveryCompanies = (payload) => ({ type: types.GET_DELIVERY_COMPANIES, payload });
export const getDeliveryCompaniesStart = () => ({ type: types.GET_DELIVERY_COMPANIES_START });
export const getDeliveryCompaniesSuccess = (payload) => ({ type: types.GET_DELIVERY_COMPANIES_SUCCESS, payload });
export const getDeliveryCompaniesFail = () => ({ type: types.GET_DELIVERY_COMPANIES_FAIL });

export const getPricingPlans = (payload) => ({ type: types.GET_PRICING_PLANS, payload });
export const getPricingPlansStart = () => ({ type: types.GET_PRICING_PLANS_START });
export const getPricingPlansSuccess = (payload) => ({ type: types.GET_PRICING_PLANS_SUCCESS, payload });
export const getPricingPlansFail = () => ({ type: types.GET_PRICING_PLANS_FAIL });

export const updateUserPackageSelection = (payload) => ({ type: types.UPDATE_USER_PACKAGE_SELECTION, payload });
export const updateUserPackageSelectionStart = () => ({ type: types.UPDATE_USER_PACKAGE_SELECTION_START });
export const updateUserPackageSelectionSuccess = (payload) => ({ type: types.UPDATE_USER_PACKAGE_SELECTION_SUCCESS, payload });
export const updateUserPackageSelectionFail = () => ({ type: types.UPDATE_USER_PACKAGE_SELECTION_FAIL });