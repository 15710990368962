import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import OnwayFooter from '../../components/UI/OnwayFooter'

export default function DriverLandingPage() {

	const [searchParams, setSearchParams] = useSearchParams()
	const verify = searchParams.get('verify')

	const [helperMessage, setHelperMessage] = useState('')

	//TODO: check jwt token, redirect to dashboard if valid
	//make sure not admin, force admin to login each time.
	useEffect(() => {
		if (verify === 'true') {
			setHelperMessage('Please check your email to activate your Onway account.')
		}
	}, [])

	return(
		<div>
			<div className="center">
				<div className="max-width-md mx-auto">
					<div className="mx-auto has-text-centered">
						<img width="240px" src={process.env.PUBLIC_URL + '/logo-purple.png'} />
					</div>
					<h1 className="is-size-1 has-text-centered my-4">For Drivers</h1>
					{helperMessage && <p className="has-text-info has-text-centered mb-4">{helperMessage}</p>}
					<div>
						<Link to="/driver/sign-up"><button className="button is-fullwidth has-background-grey-light mb-4">Sign Up</button></Link>
						<Link to="/driver/sign-in"><button className="button is-fullwidth has-background-grey-light">Sign In</button></Link>
					</div>
				</div>
			</div>
			<OnwayFooter />
		</div>
	)
}