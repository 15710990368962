import * as types from './actionTypes';
import { syncLoading } from '../../../utils/reduxHelpers';

const initialState = {
    pricingPlans: [],
    deliveryCompanies: [],
    user: null,
    loadingHandler: {
        get_pricing_plans_loading: false,
        get_delivery_companies_loading: false,
        update_user_package_selection_loading: false
    }
};

const packageSelectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DELIVERY_COMPANIES_START: {
            return {
                ...state,
                loadingHandler: syncLoading(state.loadingHandler, 'get_delivery_companies_loading', 'start')
            };
        }
        case types.GET_DELIVERY_COMPANIES_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                deliveryCompanies: data,
                loadingHandler: syncLoading(state.loadingHandler, 'get_delivery_companies_loading', 'end')
            };
        }
        case types.GET_DELIVERY_COMPANIES_FAIL: {
            return { ...state, loadingHandler: syncLoading(state.loadingHandler, 'get_delivery_companies_loading', 'end') };
        }

        case types.GET_PRICING_PLANS_START: {
            return {
                ...state,
                loadingHandler: syncLoading(state.loadingHandler, 'get_pricing_plans_loading', 'start')
            };
        }
        case types.GET_PRICING_PLANS_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                pricingPlans: data,
                loadingHandler: syncLoading(state.loadingHandler, 'get_pricing_plans_loading', 'end')
            };
        }
        case types.GET_PRICING_PLANS_FAIL: {
            return { ...state, loadingHandler: syncLoading(state.loadingHandler, 'get_pricing_plans_loading', 'end') };
        }

        // Update User With the Package Selected

        case types.UPDATE_USER_PACKAGE_SELECTION_START: {
            return {
                ...state,
                loadingHandler: syncLoading(state.loadingHandler, 'update_user_package_selection_loading', 'start')
            };
        }
        case types.UPDATE_USER_PACKAGE_SELECTION_SUCCESS: {
            const { data } = action.payload;

            return {
                ...state,
                user: data,
                loadingHandler: syncLoading(state.loadingHandler, 'update_user_package_selection_loading', 'end', 'success', 'true')
            };
        }
        case types.UPDATE_USER_PACKAGE_SELECTION_FAIL: {
            return { ...state, loadingHandler: syncLoading(state.loadingHandler, 'update_user_package_selection_loading', 'end', 'success', 'false') };
        }

        default:
            return state;
    }
};

export default packageSelectionReducer;
