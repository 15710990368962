import apiUrls from "../../constants/apiUrls";
import agent from "./agent";

export const getPricingPlans = async (deliveryCompanyId) => {
  const apiUrl = `${apiUrls.GET_PRICING_PLANS(deliveryCompanyId)}`;
  const { data } = await agent.get(apiUrl);
  return data;
};

export const getSinglePricingPlan = async (deliveryCompanyId, pricingPlanId) => {
  const apiUrl = `${apiUrls.GET_SINGLE_PRICING_PLAN(deliveryCompanyId, pricingPlanId)}`;
  const { data } = await agent.get(apiUrl);
  return data;
};


export const createPricingPlan = async (deliveryCompanyId, payload) => {
  const { data } = await agent.post(apiUrls.CREATE_PRICING_PLAN(deliveryCompanyId), payload);
  return data;
};

export const updatePricingPlan = async (deliveryCompanyId, pricingPlanId, payload) => {
  const { data } = await agent.put(apiUrls.UPDATE_PRICING_PLAN(deliveryCompanyId, pricingPlanId), payload);
  return data;
};

export const deletePricingPlan = async (deliveryCompanyId, pricingPlanId) => {
  return await agent.delete(apiUrls.DELETE_PRICING_PLAN(deliveryCompanyId, pricingPlanId));
};
