import React from 'react';
import styles from '../styles/PageLayout.module.css';

const PageLayout = ({ title, children, activeTab, NavigationComponent }) => {
  return (
    <div className="container">
      <h1 className="is-size-1 has-text-centered my-5">{title}</h1>
      {NavigationComponent && <NavigationComponent activeTab={activeTab} />}
      <div className={styles.page_container}>
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
