import { apiBaseUrl } from "./runTimeConst";

const pricingPlan = {
  GET_PRICING_PLANS: (deliveryCompanyId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans`,
  GET_SINGLE_PRICING_PLAN: (deliveryCompanyId, pricingPlanId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans/${pricingPlanId}`,
  CREATE_PRICING_PLAN: (deliveryCompanyId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans`,
  UPDATE_PRICING_PLAN: (deliveryCompanyId, pricingPlanId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans/${pricingPlanId}`,
  DELETE_PRICING_PLAN: (deliveryCompanyId, pricingPlanId) =>
    `${apiBaseUrl}api/v1/delivery_companies/${deliveryCompanyId}/pricing_plans/${pricingPlanId}`
};

const city = {
  GET_CITIES: () =>
    `${apiBaseUrl}api/v1/delivery_companies/cities`,
}

const deliveryCompany = {
  GET_DELIVERY_COMPANIES: () =>
    `${apiBaseUrl}api/v1/businesses/delivery_companies`,
}

const user = {
  UPDATE_USER: (userId) =>
    `${apiBaseUrl}api/v1/businesses/${userId}/delivery_package`
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...pricingPlan,
  ...city,
  ...deliveryCompany,
  ...user
}
