export const packageSelectionSelector = (state) => {
    const {
        packageSelectionReducer: {
            deliveryCompanies,
            pricingPlans,
            user,
            loadingHandler: {
                get_delivery_companies_loading: getDeliveryCompaniesLoading,
                get_pricing_plans_loading: getPricingPlansLoading,
                update_user_package_selection_loading: updateUserPackageSelectionLoading
            }
        }
    } = state;

    return {
        deliveryCompanies,
        pricingPlans,
        user,
        getDeliveryCompaniesLoading,
        getPricingPlansLoading,
        updateUserPackageSelectionLoading
    };
};