import { useParams, useNavigate } from "react-router-dom";
import { useOrder } from '../../swr/index';
import GoogleMapReact from 'google-map-react';
import OrderItems from '../../components/small/OrderItems'
import DriverName from '../../components/small/DriverName'
import DriverPhoneNumber from '../../components/small/DriverPhoneNumber'
import { useState } from 'react'
import moment from 'moment'
import { USER_ROLE_TITLE, USER_ROLE, DELIVERY_STATUS } from '../../common/constants'
import DateFormat from '../../components/small/DateFormat'
import DeliveryStatusFormat from '../../components/UI/DeliveryStatusFormat'
import PickupAddress from '../../components/small/PickupAddress'
import DeliveryCompany from '../../components/small/DeliveryCompany'
import WhatsAppShare from '../../components/small/WhatsAppShare'
import { API_URL, POST_FETCH_OPTIONS } from '../../common/constants';

export default function OrderDetailPage() {
	const { orderId } = useParams();
	const { order, isLoading, isError } = useOrder(orderId)
	const navigate = useNavigate()

	const [confirmDelete, setConfirmDelete] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const MarkerStart = (props) => {
		return(
			<div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
				<img width="12" height="12" src="/red-circle.svg" alt="delivery status icon" />
			</div>
		)
	}

	const MarkerEnd = (props) => {
		return(
			<div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
				<img width="12" height="12" src="/green-circle.svg" alt="delivery status icon" />
			</div>
		)
	}

	const handleApiLoaded = (map, maps) => {
	  // use map and maps objects
	};

	const role = localStorage.getItem('role')

	const deleteOrder = async () => {
		setErrorMessage('')

		const result = await fetch(`${API_URL.ORDERS}/${orderId}`, POST_FETCH_OPTIONS({
		  status: DELIVERY_STATUS.CANCELED
		}))
		const data = await result.json()

		if (data.success) {
			navigate(-1)
		} else {
			setErrorMessage("We couldn't delete this order. Please try again.")
		}

	}

	const deliveryAddressString = (order.delivery_block && ` Block ${order.delivery_block}, `)
	+ (order.delivery_house && `House ${order.delivery_house}, `)
		+ (order.delivery_street && `Street ${order.delivery_street}, `)
		+ (order.delivery_lane && `Lane ${order.delivery_block}, `)
		+ `${order.delivery_city}, Kuwait`

		
	return(
		<div>
			<button onClick={() => navigate(-1)} className="button ml-2 mt-2">Back</button>

			<div className="max-width-md mx-auto">
				<div className="card p-4 my-5">
					<h1 className="is-size-2 has-text-centered">{USER_ROLE_TITLE[role]} Order Details</h1>
					<hr />
					<div className="is-flex is-justify-content-space-evenly m-2">
			  		<div>
				  		<p className="has-text-weight-bold">Invoice Number</p>
							<p className="mb-2">{order.invoice_number}</p>

							<p className="has-text-weight-bold">Order Status</p>
							<p className="mb-2"><DeliveryStatusFormat status={order.status} /></p>

							<p className="has-text-weight-bold">Customer Name / Number</p>
							<p className="mb-2">{order.customer_name} / {order.customer_phone_number}</p>
						</div>
						<div className="ml-2">
							<p className="has-text-weight-bold">Delivery date / time</p>
							<p className="mb-2"><DateFormat timestamp={order.delivery_date} /></p>

							<p className="has-text-weight-bold">Payment Method</p>
							<p className="mb-2">{order.payment_method}</p>

							{role === USER_ROLE.DRIVER &&
								<div>
									<p className="has-text-weight-bold">Business Name / Number</p>
									<p>{order.User.business_name} / {order.User.phone_numbers}</p>
								</div>
							}

							{(role === USER_ROLE.BUSINESS || role === USER_ROLE.ADMIN) &&
								<div>
									<p className="has-text-weight-bold">Delivery Company Name / Number</p>
									<p>{order.delivery_company_name} / <DeliveryCompany driverId={order.driver_id} field="phone_number"/> </p>
								</div>
							}
						</div>
					</div>


					<hr />
					<WhatsAppShare 
						invoice_number={order.invoice_number}
						delivery_date={order.delivery_date}  
						pickup_address_id={order.pickup_address_id}
						delivery_city={order.delivery_city}
						delivery_block={order.delivery_block}
						delivery_street={order.delivery_street}
						delivery_lane={order.delivery_lane}
						delivery_floor={order.delivery_floor}
						delivery_apartment={order.delivery_apartment}
						delivery_house={order.delivery_house}
						total={parseFloat(order.subtotal) + parseFloat(order.delivery_fee)}
						customer_name={order.customer_name}
						phone_number={order.customer_phone_number}
						payment_method={order.payment_method}
						pickup_lat={order.pickup_lat}
						pickup_lng={order.pickup_lng}
						delivery_lat={order.delivery_lat}
						delivery_lng={order.delivery_lng}
						delivery_additional_directions={order.delivery_additional_directions}
					/>

					{(role === USER_ROLE.BUSINESS && order.status === DELIVERY_STATUS.CREATED) &&

						<div className="has-text-right">
							{errorMessage && <p className="is-pulled-left has-text-danger">{errorMessage}</p>}
							{!confirmDelete && <button onClick={() => setConfirmDelete(true)} className="button has-background-grey-light">CANCEL ORDER</button>}
							{confirmDelete && <button onClick={() => deleteOrder()} className="button is-danger">Confirm?</button>}
						</div>
					}
					{(role === USER_ROLE.ADMIN && order.status !== DELIVERY_STATUS.CANCELED) &&

						<div className="has-text-right">
							{errorMessage && <p className="is-pulled-left has-text-danger">{errorMessage}</p>}
							{!confirmDelete && <button onClick={() => setConfirmDelete(true)} className="button has-background-grey-light">CANCEL ORDER</button>}
							{confirmDelete && <button onClick={() => deleteOrder()} className="button is-danger">Confirm?</button>}
						</div>
					}
				</div>

				<OrderItems orderId={orderId} />

				<div className="card p-4 my-4">
					<h1 className="is-size-4 has-text-centered has-text-weight-bold mb-2">Order Invoice</h1>
					<table className="table mx-auto is-bordered is-fullwidth">
						<tbody>
							<tr>
								<td><span className="has-text-weight-bold">Subtotal:</span> {parseFloat(order.subtotal).toFixed(3)} KWD</td>
								<td></td>
							</tr>
							<tr>
								<td><span className="has-text-weight-bold">Delivery fee:</span> {parseFloat(order.delivery_fee).toFixed(3)} KWD</td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td className="has-background-grey-light has-text-right"><span className="has-text-weight-bold">Total:</span> {Math.abs(parseFloat(order.delivery_fee) + parseFloat(order.subtotal)).toFixed(3)} KWD</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="card p-4 my-5">
					<h1 className="is-size-4 has-text-centered has-text-weight-bold mb-2">Order Delivery</h1>
					
					{(role === USER_ROLE.BUSINESS || role === USER_ROLE.ADMIN) &&
						<div className="mb-4">
							<p className="has-text-weight-bold">Driver Name / Number</p>
							<p className="mb-4"><DriverName driverId={order.driver_id} /> / <DriverPhoneNumber driverId={order.driver_id} /></p>
						</div>
					}
		

		  		<div className="is-flex mb-4">
		  			<div>
				  		<p className="has-text-weight-bold">Pickup Address</p>
				  		<p><PickupAddress field="place_name" addressId={order.pickup_address_id} /></p>
				  		<p className="mb-2"><PickupAddress field="place_address" addressId={order.pickup_address_id} /></p>
			  		</div>
			  		<a 
			  			href={`https://www.google.com/maps/dir/?api=1&destination=${order.pickup_lat},${order.pickup_lng}`} 
			  			target="_blank" 
			  			className="ml-auto is-align-self-flex-start button is-small">
			  			Google Maps Direction
			  		</a>
		  		</div>

		  		<div className="is-flex mb-4">
		  			<div>
				  		<p className="has-text-weight-bold">Delivery Address</p>
				  		<p>
				  			{order.delivery_city},
				  			{order.delivery_block != 0 && <span>block {parseInt(order.delivery_block)}, </span>}
				  			{order.delivery_street != 0 && <span>{order.delivery_street}, </span>}
				  			{order.delivery_lane != 0 && <span>ln {parseInt(order.delivery_lane)} </span>}
				  		</p>
				  		<p className="mb-2">
				  			{order.delivery_floor != 0 && <span>floor {parseInt(order.delivery_floor)}, </span>} 
				  			{order.delivery_apartment != 0 && <span>apartment {parseInt(order.delivery_apartment)}, </span>} 
				  			{order.delivery_house != 0 && <span>house {parseInt(order.delivery_house)}</span>}
				  		</p>
				  	</div>
				  	<a 
				  		href={`https://www.google.com/maps/dir/?api=1&destination=${deliveryAddressString}`} 
				  		target="_blank" 
				  		className="ml-auto is-align-self-flex-start button is-small">
				  		Google Maps Direction
				  	</a>
		  		</div>

		  		{order.delivery_additional_directions &&
		  		<div className="mb-4">
		  			<p className="has-text-weight-bold">Additional Delivery Directions</p>
		  			<p>{order.delivery_additional_directions}</p>
		  		</div>
		  		}

					<div className="is-flex mb-1">
						<p className="mr-3">Pickup Address Location: <img width="12" height="12" src="/red-circle.svg" alt="delivery status icon" /></p>
						<p>Delivery Address Location: <img width="12" height="12" src="/green-circle.svg" alt="delivery status icon" /></p>
					</div>

					<div style={{ height: '60vh', width: '100%' }}>
						<GoogleMapReact
						  bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
						  defaultCenter={{lat: 29.3000, lng: 47.982104}}
						  defaultZoom={11}
						  yesIWantToUseGoogleMapApiInternals
						  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
						>
						  <MarkerStart
						    lat={parseFloat(order.pickup_lat)}
						    lng={parseFloat(order.pickup_lng)}
						   />
						  <MarkerEnd
						  	lat={parseFloat(order.delivery_lat)}
						  	lng={parseFloat(order.delivery_lng)}
						  />
						</GoogleMapReact>
					</div>
				</div>
			</div>
		</div>
	)
}