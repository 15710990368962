import AdminFooterNavigation from '../../components/AdminFooterNavigation'
import TrackingDrivers from '../../components/TrackingDrivers'

export default function AdminTracking() {
	return (
		<div className="container">
			<h1 className="is-size-1 has-text-centered my-5">Order Tracking</h1>
			<AdminFooterNavigation activeTab={'admin-tracking'} />
			<TrackingDrivers />
		</div>
	)
}