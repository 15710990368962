import { useEffect, useState, useRef } from 'react'
import { getAutoCompletePlaces } from '../common/helpers'
import GoogleMapReact from 'google-map-react';
import { API_URL, POST_FETCH_OPTIONS } from '../common/constants'
import { useSWRConfig } from 'swr'


export default function AddressAutoComplete({ placeholder, className, showPredictions, setShowPredictions, callback }) {
	
	//Delivery Address Queries from Google Places
	const [timer, setTimer] = useState()
	const [predictions, setPredictions] = useState([])
	const [searchText, setSearchText] = useState('')
	const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState()

	const [mapShown, setMapShown] = useState(false)
	const [hoverDistance, setHoverDistance] = useState(10)
	const [markedLat, setMarkedLat] = useState(0)
	const [markedLng, setMarkedLng] = useState(0)

	const [addingAddress, setAddingAddress] = useState(false)
	const [addressName, setAddressName] = useState('')

	const { mutate } = useSWRConfig()
 
	const queryPlaces = (e) => {
		if (timer) {
			clearTimeout(timer)
		}
		setSearchText(e.target.value)

		setTimer(setTimeout(() => {
			if (e.target.value.trim() === '') { setShowPredictions(false); return }

			getAutoCompletePlaces(e.target.value.trim(), function(data) {
				//TODO: add error check
				setShowPredictions(true)
				setPredictions(data)
			})
		}, 1000))
	}

	const selectDeliveryAddress = (place) => {
		setSearchText(place.description)
		setShowPredictions(false)
		setSelectedDeliveryAddress(place)
		// setMapShown(true)
		// console.log(place)
		if (callback) { callback(place) }
	}

	const showMap = () => {
		setMapShown(true)
	}


	const handleApiLoaded = (map, maps) => {
	  // use map and maps objects
	  console.log(map)
	};


	const Marker = (props) => {
		console.log(props)
		if (props.$hover) {
			setHoverDistance(70)
		} else {
			setHoverDistance(10)
		}
		return (
		
			<div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
				<img width="10" height="10" src="/red-circle.svg" alt="delivery status icon" />
				{props.$hover &&
					<div className="box">
						<button onClick={() => setAddingAddress(true)} className="button is-info">Confirm address location?</button>
					</div>
				}
			</div>

		)
	}

	const confirmAddress = (e) => {
		e.preventDefault();

		const lat = markedLat;
		const lng = markedLng;
		const name = addressName;
		callback({
			lat,
			lng,
			description: name,
			place_id: null
		})
		setAddingAddress(false)
		setSearchText(name)
		// mutate([API_URL.PICKUP_ADDRESSES, `?page=1&limit=5`], async data => {
		// 	const newAddress = await fetch(API_URL.SAVE_COORDINATE_ADDRESS, POST_FETCH_OPTIONS({ lat, lng, name })).then(res => res.json())
		// 	setAddressName('')
		// 	setAddingAddress(false)
		// 	setMapShown('')
		// 	setMarkedLat(0)
		// 	setMarkedLng(0)
		// 	return [...data, newAddress]
		// })
	}

	const handleMapClick = ({ x, y, lat, lng, event }) => {
		setMarkedLat(lat)
		setMarkedLng(lng)
	}

	return(
		<div className={className}>

			<div className="field mb-0">
			  <p className="control has-icons-right">
			  	<input placeholder={placeholder || 'Search addresses'} name="deliveryAddress" value={searchText} onChange={queryPlaces} autoComplete="off" className="input" type="text" />
			    {searchText && 
				    <span className="icon is-small is-right">
				    	<button onClick={() => { setSearchText(''); setMapShown(false) } } className="delete"></button>
				    </span>
			  	}
			  </p>
			</div>

			{mapShown &&
				<div>

					<div className={`modal ${addingAddress ? 'is-active' : ''}`}>
						<div className="modal-background"></div>
						<div className="modal-content">
							<div className="box">
								<form onSubmit={confirmAddress}>
									<label className="label">Location Name</label>
									<div className="is-flex is-flex-direction-row">
										<input value={addressName} onChange={(e) => setAddressName(e.target.value)} name="name" className="input mb-2" type="text" />
										<input type="submit" className="button-width ml-4 is-info button" name="Create Address" />
									</div>
								</form>
							</div>
						</div>
						<button onClick={() => setAddingAddress(false)} className="modal-close is-large" aria-label="close"></button>
					</div>

					<div style={{ height: '60vh', width: '100%' }}>
						<GoogleMapReact
						  bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
						  defaultCenter={{lat: 29.3000, lng: 47.982104}}
						  defaultZoom={11}
						  onClick={handleMapClick}
						  hoverDistance={hoverDistance}
						  yesIWantToUseGoogleMapApiInternals
						  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
						>
						  <Marker
						    lat={markedLat}
						    lng={markedLng}
						   />
						</GoogleMapReact>
					</div>

				</div>
			}

			<div className={`${showPredictions ? 'is-active' : ''} dropdown is-pulled-left`}>
				<div className="dropdown-menu" id="dropdown-menu" role="menu">
				  <div className="dropdown-content">
				  	{predictions.length ? predictions.map(prediction => (
				  		<a key={prediction.place_id} onClick={() => selectDeliveryAddress(prediction)} className="dropdown-item">{prediction.description}</a>
				  	)) : <a onClick={() => setShowPredictions(false)} className="dropdown-item">No results found</a>}	
				  	<a onClick={() => showMap()} className="dropdown-item">Select Address on Map</a>
				  </div>
				</div>
			</div>
		</div>
	)
}


  