import * as types from './actionTypes';

export const getPricingPlans = (payload) => ({ type: types.GET_PRICING_PLANS, payload });
export const getPricingPlansStart = () => ({ type: types.GET_PRICING_PLANS_START });
export const getPricingPlansSuccess = (payload) => ({ type: types.GET_PRICING_PLANS_SUCCESS, payload });
export const getPricingPlansFail = () => ({ type: types.GET_PRICING_PLANS_FAIL });

export const createPricingPlan = (payload) => ({ type: types.CREATE_PRICING_PLAN, payload });
export const createPricingPlanStart = () => ({ type: types.CREATE_PRICING_PLAN_START });
export const createPricingPlanSuccess = (payload) => ({ type: types.CREATE_PRICING_PLAN_SUCCESS, payload });
export const createPricingPlanFail = () => ({ type: types.CREATE_PRICING_PLAN_FAIL });


export const updatePricingPlan = (payload) => ({ type: types.UPDATE_PRICING_PLAN, payload });
export const updatePricingPlanStart = () => ({ type: types.UPDATE_PRICING_PLAN_START });
export const updatePricingPlanSuccess = (payload) => ({ type: types.UPDATE_PRICING_PLAN_SUCCESS, payload });
export const updatePricingPlanFail = () => ({ type: types.UPDATE_PRICING_PLAN_FAIL });


export const deletePricingPlan = (payload) => ({ type: types.DELETE_PRICING_PLAN, payload });
export const deletePricingPlanStart = () => ({ type: types.DELETE_PRICING_PLAN_START });
export const deletePricingPlanSuccess = (payload) => ({ type: types.DELETE_PRICING_PLAN_SUCCESS, payload });
export const deletePricingPlanFail = () => ({ type: types.DELETE_PRICING_PLAN_FAIL });


// Cities
export const getCities = (payload) => ({ type: types.GET_CITIES, payload });
export const getCitiesStart = () => ({ type: types.GET_CITIES_START });
export const getCitiesSuccess = (payload) => ({ type: types.GET_CITIES_SUCCESS, payload });
export const getCitiesFail = () => ({ type: types.GET_CITIES_FAIL });