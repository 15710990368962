
import { useTypeOfProducts } from '../swr/admin'
import { useState } from 'react'
import { API_URL, POST_FETCH_OPTIONS } from '../common/constants'
import { useSWRConfig } from 'swr'

export default function TypeOfProducts() {

	const { mutate } = useSWRConfig()
	const { data, isLoading, isError } = useTypeOfProducts()
	const [productName, setProductName] = useState('')

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const addProduct = () => {

		if (!productName) {
			return
		}

		mutate(API_URL.TYPE_OF_PRODUCTS, async data => {
			const newProduct = await fetch(API_URL.TYPE_OF_PRODUCTS, POST_FETCH_OPTIONS({name: productName})).then(res => res.json())
			setProductName('')
			return [...data, newProduct]
		})
	}

	return (
		<div>
			<div className="field has-addons ">
				<div className="control is-expanded">
					<input 
						onChange={(e) => setProductName(e.target.value)} 
						className="input" value={productName} placeholder="Product Name" type="text" name="name" />
				</div>
				<div className="control">
					<button 
						onClick={() => addProduct()}  
						className="button is-info"
					>
						Add Type of Product +
					</button>
				</div>
			</div>
			<div className="box">
				{data && data.map(product => <p key={product.name}>{product.name}</p>)}
			</div>
		</div>
	)
}