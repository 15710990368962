import moment from 'moment'
import { useAddress } from '../../swr/index'

export default function WhatsAppShare({ 
	invoice_number,
	delivery_date, 
	pickup_address_id,
	delivery_city,
	delivery_block,
	delivery_street,
	delivery_lane,
	delivery_floor,
	delivery_apartment,
	delivery_house,
	total,
	customer_name,
	phone_number,
	payment_method,
	pickup_lat,
	pickup_lng,
	delivery_lat,
	delivery_lng,
	delivery_additional_directions
}) {

	const { address, isLoading, isError } = useAddress(pickup_address_id) 

	if (isLoading) return 'Loading...'
	if (isError) return "Error..."

	const deliveryBlock = delivery_block ? `block ${parseInt(delivery_block)}, ` : ''
	const deliveryStreet = delivery_street ? `${parseInt(delivery_street)}, ` : ''
	const deliveryLane = delivery_lane ? `ln ${parseInt(delivery_lane)}, ` : ''
	const deliveryFloor = delivery_floor ? `floor ${parseInt(delivery_floor)}, ` : ''
	const deliveryApartment = delivery_block ? `apartment ${parseInt(delivery_apartment)}, ` : ''
	const deliveryHouse = delivery_block ? `house ${parseInt(delivery_house)}` : ''

	const deliveryString = `${delivery_city}, ${deliveryBlock} ${deliveryStreet} ${deliveryLane} ${deliveryFloor} ${deliveryApartment} ${deliveryHouse}`

	// mobile: whatsapp://
	const encoded = encodeURIComponent(`Invoice: ${invoice_number}\nDelivery date/time: ${moment(delivery_date).format('D/M/YYYY, h:mm A')}\n\nPickup Address: ${address?.place_address}\nPickup Address Google Maps: https://www.google.com/maps/dir/?api=1&destination=${pickup_lat},${pickup_lng}\n\nDelivery Address: ${deliveryString}\n${delivery_additional_directions ? `Additional Delivery Directions: ${delivery_additional_directions}\n` : ''}Delivery Address Google Maps: https://www.google.com/maps/dir/?api=1&destination=${delivery_lat},${delivery_lng}\n\nCustomer Name / Phone Number: ${customer_name} / ${phone_number} \nPayment Method: ${payment_method}\nOrder Invoice Total: ${total} KWD`)
	
	return(
		<div>
			<a 
				href={`https://web.whatsapp.com/send?text=${encoded}`} 
				data-action="share/whatsapp/share" 
				target="_blank"
				rel="noreferrer"
			>
				Share via WhatsApp
			</a>
		</div>
	)
}