import React from 'react';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import styles from '../common/styles/Form.module.css';
import { preventEnterKey } from '../../constants/preventEnterKey';

const DeliveryCompanyDropdown = ({ deliveryCompanies, setDeliveryCompanyId }) => {
  const options = deliveryCompanies?.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  const handleSelection = (selectedValue) => {
    setDeliveryCompanyId(selectedValue);
  };

  return (
    <Formik initialValues={{ deliveryCompany: '' }}>
      {({ setFieldValue }) => (
        <Form onKeyDown={preventEnterKey}>
          <div className={styles.form_group}>
            <label htmlFor="deliveryCompany">Delivery Company</label>
            <Select
              id="deliveryCompany"
              name="deliveryCompany"
              options={options}
              placeholder="Select Delivery Company"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                setFieldValue('deliveryCompany', selectedOption ? selectedOption.value : '');
                handleSelection(selectedOption.value);
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DeliveryCompanyDropdown;