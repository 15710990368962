export default function Pagination({ totalPages, currentPage, setPage }) {

	const Pages = () => {
		const rows = []
		for (let i = 0; i < totalPages; i++) {
			rows.push(<li key={i} onClick={() => setPage(i+1)}><a className={`pagination-link ${currentPage === i+1 ? 'is-current' : ''}`} aria-label={`Goto page ${i+1}`}>{i+1}</a></li>)
		}
		return rows || <li></li>
	}

	return(
		<nav className="pagination is-centered" role="navigation" aria-label="pagination">
		  <ul className="pagination-list">
		    {/*<li><a class="pagination-previous">Previous</a></li>*/}
		    {/* <li key={0} onClick={() => setPage(1)}><a className={`pagination-link ${currentPage === 1 ? 'is-current' : ''}`} aria-label={`Goto page ${1}`}>{1}</a></li> */}
		    <Pages />
				{/* <li key={totalPages} onClick={() => setPage(totalPages)}><a className={`pagination-link ${currentPage === totalPages ? 'is-current' : ''}`} aria-label={`Goto page ${totalPages}`}>{totalPages}</a></li> */}
		    {/*<li><a class="pagination-next">Next page</a></li>*/}
		  </ul>
		</nav>
	)
}