import { io } from "socket.io-client";
import { useState, useEffect } from 'react';
import { GET_FETCH_OPTIONS, API_URL } from '../common/constants'
import moment from 'moment'

export default function TrackingDrivers() {

	// const [socket, setSocket] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [driverLocations, setDriverLocations] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	// useEffect(() => {
	// 	const newSocket = io(process.env.REACT_APP_API_URL, { transports : ['websocket'] });
	// 	setSocket(newSocket);
	// 	newSocket.on('connect', () => {
	// 	  console.log('connected')
	// 	  newSocket.on('test', () => {
	// 	  	console.log('test')
	// 	  })
	// 	  newSocket.emit('test', ':)')
	// 	})

	// 	return () => newSocket.close();
	// }, [setSocket])

	async function getDriverLocations() {
		setErrorMessage('')
		try {
			const response = await fetch(API_URL.TRACKING, GET_FETCH_OPTIONS());
			const data = await response.json()
			if (data.success) {
				const cleaned = data.sockets.filter(driverLocation => driverLocation.lat)
				if (!cleaned.length) {
					setErrorMessage('No current drivers found.')
				}
				setDriverLocations(cleaned)
			} else {
				setErrorMessage("Couldn't load any driver locations.")
			}

		} catch (err) {
			console.log(err)
		}
	}

	return(
		<div className="has-text-centered">
			<button className={`button is-info ${isLoading ? 'is-loading' : ''}`} onClick={() => getDriverLocations()}>Load Tracking</button>
			{errorMessage && <p className="has-text-danger my-4">{errorMessage}</p>}
			{driverLocations.length > 0 && 
				<table className="mt-5 table mx-auto is-bordered">
					<thead>
						<tr>
							<th>User Id</th>
							<th>Latitude</th>
							<th>Longitude</th>
							<th>Last Updated</th>
						</tr>
					</thead>
					<tbody>
						{driverLocations.map((location, index) => (
							<tr key={index}>
								<td>{location.userId}</td>
								<td>{location.lat}</td>
								<td>{location.lng}</td>
								<td>{moment(location.updatedAt).format('h:mm a')}</td>
							</tr>
						))}
					</tbody>
				</table>
			}
		</div>
	)
}