import { Link } from 'react-router-dom';

export default function FooterNavigation({ activeTab }) {
	return (
		<div className="tabs is-toggle is-fullwidth">
		  <ul>
		    <li className={`${activeTab === 'dashboard' ? 'is-active' : ''}`}>
		      <Link to="/dashboard">
		        <span>Order Tracking</span>
		      </Link>
		    </li>
		    <li className={`${activeTab === 'order' ? 'is-active' : ''}`}>
		      <Link to="/place-order">
		        <span>Place Order</span>
		      </Link>
		    </li>
		    <li className={`${activeTab === 'products' ? 'is-active' : ''}`}>
		      <Link to="/add-products">
		        <span>Add Products / Addresses</span>
		      </Link>
		    </li>
			<li className={`${activeTab === 'package-selection' ? 'is-active' : ''}`}>
		      <Link to="/packageSelection">
		        <span>Package Selection</span>
		      </Link>
		    </li>
		    <li className={`${activeTab === 'settings' ? 'is-active' : ''}`}>
		      <Link to="/settings">
		        <span>Settings</span>
		      </Link>
		    </li>
		  </ul>
		</div>
	)
}