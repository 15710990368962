export const pricingPlanListSelector = (state) => {
    const {
        pricingPlanReducer: {
            pricingPlans,
            loadingHandler: {
                get_pricing_plans_loading: getPricingPlansLoading,
                create_pricing_plan_loading: createPricingPlanLoading,
                update_pricing_plan_loading: updatePricingPlanLoading,
                delete_pricing_plan_loading: deletePricingPlanLoading,
            }
        }
    } = state;

    return {
        pricingPlans,
        getPricingPlansLoading,
        createPricingPlanLoading,
        updatePricingPlanLoading,
        deletePricingPlanLoading,
    };
};

export const cityListSelector = (state) => {
    const {
        pricingPlanReducer: {
            cities,
            loadingHandler: {
                get_cities_loading: getCitiesLoading
            }
        }
    } = state;

    return {
        cities,
        getCitiesLoading,
    };
};
