import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { API_URL, POST_FETCH_OPTIONS } from '../../common/constants'

export default function ResetPage() {

	const [searchParams, setSearchParams] = useSearchParams();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const token = searchParams.get('token')

	console.log(token)

	const resetPassword = (e) => {
		e.preventDefault();
		setErrorMessage('')
		setSuccessMessage('')
		if (!password || !confirmPassword) {
			setErrorMessage('Please fill out the form.')
			return
		}
		if (password.length < 8) {
			setErrorMessage('Please enter a password that\'s 8 characters or more')
			return
		}
		if (password !== confirmPassword) {
			setErrorMessage('Passwords don\'t match.')
			return
		}
		
		fetch(API_URL.UPDATE_PASSWORD, POST_FETCH_OPTIONS({ token, password }))
			.then(res => res.json())
			.then(data => {
				data.success ? setSuccessMessage(data.message) : setErrorMessage(data.message)
			})
	}

	return(
		<div className="center">
			<div className="max-width-md mx-auto">
				<h1 className="is-size-1 has-text-centered my-4">Reset Password</h1>
				<form onSubmit={resetPassword}>
					<label className="label">Password</label>
					<input name="password" onChange={(e) => setPassword(e.target.value)} className="input mb-4" type="password" />
					<label className="label">Confirm Password</label>
					<input name="confirm-password" onChange={(e) => setConfirmPassword(e.target.value)} className="input mb-4" type="password" />
					<input className="has-background-grey-light button is-fullwidth mt-2" type="submit" value="Update Password" />
				</form>
				<div className="my-3" />
				{successMessage && <p className="has-text-centered has-text-info">{successMessage}</p>}
				{errorMessage && <p className="has-text-centered has-text-danger">{errorMessage}</p>}
			</div>
		</div>
	)
}