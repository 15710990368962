import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { API_URL, POST_FETCH_OPTIONS } from '../../common/constants'

export default function ActivationPage() {

	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState(true)
	const [isSuccess, setIsSuccess] = useState(false)

	const token = searchParams.get('token')

	console.log(token)
	useEffect(async () => {
		const activated = await fetch(API_URL.ACTIVATE_ACCOUNT, POST_FETCH_OPTIONS({ token: token })).then(res => res.json())
		setIsSuccess(activated.success)
		setIsLoading(false)
	}, [])

	return(
		<div className="container">
			{isLoading && <p>Attempting to verify account...</p>}
			{(!isLoading && isSuccess) && <p>Account verified!</p>}
			{(!isLoading && !isSuccess) && <p>We couldn't verify this account.</p>}
		</div>
	)
}