import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

export default function OnwayFooter({ position = 'absolute'}) {
	return(
		<footer style={{"position": position, "bottom": "0px", "width": '100%'}} className="footer">
		  <div className="content has-text-centered">
		  	<div className="is-flex is-justify-content-space-evenly flex-wrap">
		  		<div className="is-clickable mb-2">
		  			<FontAwesomeIcon icon={faInstagramSquare} size="lg" />
		  			<p><a href="https://www.instagram.com/onwayq8" target="_blank">@onwayq8</a></p>
		  		</div>
		  		<div className="is-clickable mb-2 mx-2">
		  			<FontAwesomeIcon icon={faEnvelope} />
		  			<p><a href="mailto:onwayq8@gmail.com">onwayq8@gmail.com</a></p>
		  		</div>
		  		<div className="is-clickable">
		  			<FontAwesomeIcon icon={faPhone} />		
		  			<p><a href="tel:96565593644">+965 65593644</a></p>	  
		  		</div>
		    </div>
		  </div>
		</footer>
	)
}