import { API_URL, GET_FETCH_OPTIONS } from './constants'

export const getAutoCompletePlaces = (searchText, callback) => {
	fetch(`${API_URL.QUERY_PLACES}?searchText=${searchText}`, GET_FETCH_OPTIONS())
		.then(res => res.json())
		.then(data => {
			callback(data)
		})
		.catch(err => {
			callback(err)
		}) 
}

export const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('userId');
	localStorage.removeItem('role');
}