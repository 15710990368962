import { useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { API_URL, POST_FETCH_OPTIONS, USER_ROLE } from '../../common/constants'
import OnwayFooter from '../../components/UI/OnwayFooter'

export default function AdminLandingPage() {

	const navigate = useNavigate();

	const [errorMessage, setErrorMessage] = useState('')

	const handleSignUp = (e) => {
		e.preventDefault()
		const formData = new FormData(e.target);
		const keys = [
			'email', 
			'password', 
		]

		let data = {}
		keys.forEach(key => data[key] = formData.get(key))
		data.role = USER_ROLE.ADMIN
		if (validateForm(data)) {
			fetch(API_URL.LOGIN, POST_FETCH_OPTIONS(data))
				.then(res => res.json())
				.then(data => {
					if (!data.id) { 
						setErrorMessage(data.message)
						return
					}
					localStorage.setItem('token', data.token);
					localStorage.setItem('role', data.role);
					localStorage.setItem('userId', data.id);
					navigate("/admin/dashboard")
				})
				.catch(err => {
					console.log(err)
				})	
		} else {
			setErrorMessage('Please enter a valid username / password.')
		}
	}

	const validateForm = (data) => {
		if (data.email && data.password) {
			return true
		}
		return false
	}

	return (
		<div>
			<div className="center">
				<div className="mx-auto max-width-md">
					<h1 className="is-size-1 has-text-centered my-4">Sign in</h1>
					<form onSubmit={handleSignUp}>
						<label className="label">Username</label>
						<input name="email" className="input mb-4" type="text" />

						<label className="label">Password</label>
						<input name="password" className="input mb-4" type="password" />

						<input className="button has-background-grey-light is-fullwidth" type="submit" value="Sign In" />
					</form>
				</div>
			</div>
			<OnwayFooter />
		</div>
	)
}