import { useParams, Link } from "react-router-dom";
import { useOrder } from '../../swr/index';
import DateFormat from '../../components/small/DateFormat';
import { useState, useEffect } from 'react'
import { useSWRConfig } from 'swr' 
import { API_URL, DELIVERY_STATUS, POST_FETCH_OPTIONS } from '../../common/constants';
import OrderItems from '../../components/small/OrderItems';
import PickupAddress from '../../components/small/PickupAddress';
import WhatsAppShare from '../../components/small/WhatsAppShare'

export default function OrderDetails() {

	const { orderId } = useParams();
	const { mutate } = useSWRConfig();

	const { order, isLoading, isError } = useOrder(orderId)
	const [nextDeliveryStatus, setNextDeliveryStatus] = useState('')
	const [showProducts, setShowProducts] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		if (order) {
			const nextStatus = getNextStatus(order.status)
			setNextDeliveryStatus(nextStatus)
		}
	}, [order])

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const updateStatus = async (order) => {
		setErrorMessage('')

		const userId = localStorage.getItem('userId')
		if (! userId) { return }

		try {
		  const result = await fetch(`${API_URL.ORDERS}/${order.id}`, POST_FETCH_OPTIONS({
		    ...order,
		    driver_id: userId,
		    status: nextDeliveryStatus,
		  }))
		  const data = await result.json()

		  if (! data.success) { 
		    setErrorMessage(data.message) 
		    return
		  }

		} catch (err) {
		  setErrorMessage(err.message) 
		}
	}

	const getNextStatus = (currentStatus) => {
		const statuses = Object.values(DELIVERY_STATUS)
		const index = statuses.indexOf(currentStatus)
		return statuses[index + 1]
	}

	const deliveryAddressString = (order.delivery_block && ` Block ${order.delivery_block}, `)
	+ (order.delivery_house && `House ${order.delivery_house}, `)
		+ (order.delivery_street && `Street ${order.delivery_street}, `)
		+ (order.delivery_lane && `Lane ${order.delivery_block}, `)
		+ `${order.delivery_city}, Kuwait`

	return(
		<div>
			<Link to={`/driver/leaderboard`} className="button ml-2 mt-2">Back</Link>
			<div className="mx-auto max-width-md">
		  	<h1 className="is-size-1 has-text-centered my-5">Delivery Order</h1>
		  	
		  	<div className={`modal ${showProducts ? 'is-active' : ''}`}>
		  		<div className="modal-background"></div>
		  		<div className="modal-content">
		  			<div className="box">
		  				<OrderItems orderId={orderId} />
		  			</div>
		  		</div>
		  		<button onClick={() => setShowProducts(false)} className="modal-close is-large" aria-label="close"></button>
		  	</div>

		  	<div className="card p-2 m-4">
		  		{errorMessage && <p className="has-text-danger has-text-centered mb-2">{errorMessage}</p>}
		  		<div className="is-pulled-right">
		  		{order.status === DELIVERY_STATUS.DELIVERED
		  			? <p>Order complete</p>
		  			: <button className="is-small" onClick={() => { 
		  				const options = { optimisticData: { ...order, status: nextDeliveryStatus }, rollbackOnError: true }
		  				mutate(`${API_URL.ORDERS}/${order.id}`, updateStatus(order), options)
		  			}}>Action: {nextDeliveryStatus}</button>
		  		}
		  		</div>
		  		<div className="pt-2">
		  			<hr />
		  		</div>
		  		
		  		<div className="is-flex is-justify-content-space-evenly m-2">
			  		<div>
				  		<p className="has-text-weight-bold">Invoice Number</p>
							<p className="mb-2">{order.invoice_number}</p>

							<p className="has-text-weight-bold">Order Items</p>
							<p className="mb-2"><a onClick={() => setShowProducts(true)}>{order.item_ids.length} item(s)</a></p>

							<p className="has-text-weight-bold">Customer Name / Number</p>
							<p className="mb-2">{order.customer_name} / {order.customer_phone_number}</p>

						</div>
						<div className="ml-2">
							<p className="has-text-weight-bold">Pickup date / time</p>
							<p className="mb-2"><DateFormat timestamp={order.delivery_date} /></p>

							<p className="has-text-weight-bold">Payment Method</p>
							<p className="mb-2">{order.payment_method}</p>

							<p className="has-text-weight-bold">Business Name / Number</p>
							<p>{order.User.business_name}: {order.User.phone_numbers}</p>
						</div>
					</div>

					<WhatsAppShare 
						invoice_number={order.invoice_number}
						delivery_date={order.delivery_date}  
						pickup_address_id={order.pickup_address_id}
						delivery_city={order.delivery_city}
						delivery_block={order.delivery_block}
						delivery_street={order.delivery_street}
						delivery_lane={order.delivery_lane}
						delivery_floor={order.delivery_floor}
						delivery_apartment={order.delivery_apartment}
						delivery_house={order.delivery_house}
						total={parseFloat(order.subtotal) + parseFloat(order.delivery_fee)}
						customer_name={order.customer_name}
						phone_number={order.customer_phone_number}
						payment_method={order.payment_method}
						pickup_lat={order.pickup_lat}
						pickup_lng={order.pickup_lng}
						delivery_lat={order.delivery_lat}
						delivery_lng={order.delivery_lng}
						delivery_additional_directions={order.delivery_additional_directions}
					/>
		  	</div>

		  	<div className="card p-4 m-4">
		  		<div className="is-flex mb-4">
		  			<div>
				  		<p className="has-text-weight-bold">Pickup Address</p>
				  		<p><PickupAddress field="place_name" addressId={order.pickup_address_id} /></p>
				  		<p className="mb-2"><PickupAddress field="place_address" addressId={order.pickup_address_id} /></p>
			  		</div>
			  		<a 
			  			href={`https://www.google.com/maps/dir/?api=1&destination=${order.pickup_lat},${order.pickup_lng}`} 
			  			target="_blank" 
			  			className="ml-auto is-align-self-flex-start button is-small">
			  			Google Maps Direction
			  		</a>
		  		</div>

		  		<div className="is-flex">
		  			<div>
				  		<p className="has-text-weight-bold">Delivery Address</p>
				  		<p>
				  			{order.delivery_city},
				  			{order.delivery_block != 0 && <span>block {parseInt(order.delivery_block)}, </span>}
				  			{order.delivery_street != 0 && <span>{order.delivery_street}, </span>}
				  			{order.delivery_lane !=0 && <span>ln {parseInt(order.delivery_lane)}</span>}
				  		</p>
				  		<p className="mb-2">
				  			{order.delivery_floor != 0 && <span>floor {parseInt(order.delivery_floor)}, </span>} 
				  			{order.delivery_apartment != 0 && <span>apartment {parseInt(order.delivery_apartment)}, </span>} 
				  			{order.delivery_house != 0 && <span>house {parseInt(order.delivery_house)}</span>}
				  		</p>
				  	</div>
				  	<a 
				  		href={`https://www.google.com/maps/dir/?api=1&destination=${deliveryAddressString}`} 
				  		target="_blank" 
				  		className="ml-auto is-align-self-flex-start button is-small">
				  		Google Maps Direction
				  	</a>
		  		</div>


		  		{order.delivery_additional_directions &&
		  		<div className="mt-2 mb-4">
		  			<p className="has-text-weight-bold">Additional Delivery Directions</p>
		  			<p>{order.delivery_additional_directions}</p>
		  		</div>
		  		}

		  	</div>

		  	<div className="card p-4 m-4">
		  		<p>
		  			<span className="has-text-weight-bold">Subtotal</span> {Math.abs(order.subtotal).toFixed(3)} KWD 
		  			<span className="ml-4 has-text-weight-bold">Delivery Fee</span> {Math.abs(order.delivery_fee).toFixed(3)} KWD
		  		</p>
		  		<p className="mt-2 has-text-right">
		  			<span className="has-text-weight-bold">Total</span> {Math.abs(parseFloat(order.subtotal) + parseFloat(order.delivery_fee)).toFixed(3)} KWD
		  		</p>
		  	</div>

			</div>
		</div>
	)
}