import { useOrders, useOrdersTotal } from '../../swr/index'
import { useState } from 'react'
import Pagination from '../../components/small/Pagination'
import DateFormat from '../../components/small/DateFormat';
import { API_URL, POST_FETCH_OPTIONS, DELIVERY_STATUS } from '../../common/constants'
import { useSWRConfig } from 'swr'
import { Link, useNavigate } from 'react-router-dom';
import DriverNavigation from './Navigation'

function Table({ page, limit }) {

  const { mutate } = useSWRConfig()
  const navigate = useNavigate();

  const { orders, isLoading, isError } = useOrders(page, limit)
  const [errorMessage, setErrorMessage] = useState('')

  if (isLoading) return 'Loading...'
  if (isError) return 'Error...'

  const acceptDelivery = async (order) => {
    setErrorMessage('')
    const userId = localStorage.getItem('userId')
    if (! userId) { return }

    try {
      const result = await fetch(`${API_URL.ORDERS}/${order.id}`, POST_FETCH_OPTIONS({
        ...order,
        driver_id: userId,
        status: DELIVERY_STATUS.ACCEPTED,
      }))
      const data = await result.json()

      if (! data.success) { 
        setErrorMessage(data.message) 
        return
      }

      navigate(`/driver/leaderboard/${order.id}`)
      mutate([API_URL.ORDERS, `?page=${page}&limit=${limit}`])


    } catch (err) {
      setErrorMessage(err.message) 
    }

  }

  return(
    <div className="table-container">
      {errorMessage && <p className="has-text-danger has-text-centered mb-2">{errorMessage}</p>}
      <table className="table mx-auto is-bordered is-fullwidth">
        <thead>
          <tr>
            <th>Pickup date / time</th>
            <th>Pickup city</th>
            <th>Delivery city</th>
            <th>Business Name / Type of Products</th>
            <th>Delivery fees</th>
            <th>Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td><DateFormat timestamp={order.delivery_date} /></td>
              <td>{order.pickup_city}</td>
              <td>{order.delivery_city}</td>
              {order.User ? <td>{order.User.business_name} / {order.User.product_type || ''}</td> : <td></td>}
              <td>{Math.abs(parseFloat(order.delivery_fee)).toFixed(3)} KWD</td>
              <td>{Math.abs(parseFloat(order.delivery_fee) + parseFloat(order.subtotal)).toFixed(3)} KWD</td>
              <td align="center">
              	<button onClick={() => acceptDelivery(order)} className="button">Accept</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default function LeaderboardPage() {

	const limit = 50;
	const { total, isLoading, isError } = useOrdersTotal()

	const [currentPage, setCurrentPage] = useState(1)

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	return (
	  <div className="container">
	    <h1 className="is-size-1 has-text-centered my-5">Leaderboard</h1>
      <DriverNavigation activeTab="driver-leaderboard" />
	    <Table page={currentPage} limit={limit} />
	    <Pagination 
	      currentPage={currentPage} 
	      totalPages={Math.ceil(total / limit)} 
	      setPage={setCurrentPage}
	    />
      {total === 0 && <p className="has-text-centered">No orders currently.</p> }
	  </div>
	);
}