import React from 'react';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import styles from '../common/styles/Form.module.css';
import { preventEnterKey } from '../../constants/preventEnterKey';

const PricingPlanDropdown = ({ pricingPlans, setPricingPlanId }) => {
    const options = pricingPlans?.map((plan) => ({
        value: plan.id,
        label: plan.name,
    }));

    const handleSelection = (selectedValue) => {
        setPricingPlanId(selectedValue);
    };

    return (
        <Formik initialValues={{ pricingPlan: '' }}>
            {({ setFieldValue }) => (
                <Form onKeyDown={preventEnterKey}>
                    <div className={styles.form_group}>
                        <label htmlFor="pricingPlan">Pricing Plan</label>
                        <Select
                            id="pricingPlan"
                            name="pricingPlan"
                            options={options}
                            placeholder="Select Pricing Plan"
                            classNamePrefix="react-select"
                            onChange={(selectedOption) => {
                                setFieldValue('pricingPlan', selectedOption ? selectedOption.value : '');
                                handleSelection(selectedOption.value);
                            }}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default PricingPlanDropdown;