
import { useDeliveryZones } from '../swr/admin'
import { useState, useEffect } from 'react'
import { API_URL, POST_FETCH_OPTIONS } from '../common/constants'
import { useSWRConfig } from 'swr'
import SelectCity from './small/SelectCity';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DeliveryFees() {

	const { mutate } = useSWRConfig()
	const { data, isLoading, isError } = useDeliveryZones()

	const [zoneName, setZoneName] = useState('')
	const [fee, setFee] = useState('')
	const [cities, setCities] = useState([])

	const [addingDeliveryZone, setAddingDeliveryZone] = useState(false)

	const [zone, setZone] = useState({})
	const [selectedCity, setSelectedCity] = useState('')


	useEffect(() => {
		if (!zone.name) {
			setFee('')
			setZoneName('')
			setSelectedCity('')
		}

	}, [zone])

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const addDeliveryZone = async (e) => {
		e.preventDefault();
		if (!zoneName || !fee) {
			return
		}

		const newZone = await fetch(API_URL.DELIVERY_ZONE, POST_FETCH_OPTIONS({
			name: zoneName,
			fee: parseFloat(fee),
			cities: []
		})).then(res => res.json())

		setZoneName('')
		setFee('')
		setAddingDeliveryZone(false)
		mutate(API_URL.DELIVERY_ZONE)
	}

	const showZone = (toEdit) => {
		setZoneName(toEdit.name)
		setFee(toEdit.fee)
		setZone(toEdit)
	}

	const editZone = async (e, city, isAdd) => {
		e.preventDefault();
		if (!city) {
			return
		}
		
		let newCities = []
		if (isAdd) {
			newCities = [...zone.cities, city]
		} else {
			const indexOf = zone.cities.indexOf(city);
			newCities = zone.cities
			newCities.splice(indexOf, 1)
		}

		try {
			const newZone = await fetch(API_URL.DELIVERY_ZONE, POST_FETCH_OPTIONS({
				id: zone.id,
				name: zone.name,
				fee: parseFloat(zone.fee),
				cities: newCities,
				newCity: isAdd ? city : null
			})).then(res => res.json())

			if (newZone.message) {
				toast.error(newZone.message)
				return
			}

			setZone(newZone)
			mutate(API_URL.DELIVERY_ZONE)
		} catch (err) {
			console.log(err)
		}



	}

	const editPrimaryZone = async (e, name, fee) => {
		e.preventDefault();
		const newZone = await fetch(API_URL.DELIVERY_ZONE, POST_FETCH_OPTIONS({
			id: zone.id,
			name: name,
			fee: parseFloat(fee),
			cities: zone.cities
		})).then(res => res.json())
		setZone({})
		mutate(API_URL.DELIVERY_ZONE)

	}

	return (
		<div>
			<ToastContainer />
			<div className={`modal ${addingDeliveryZone ? 'is-active' : ''}`}>
				<div className="modal-background"></div>
				<div className="modal-content">
					<div className="box">
						<button onClick={(e) => {e.preventDefault(); setAddingDeliveryZone(false) } } className="is-pulled-right is-large is-clickable" aria-label="close">X</button>
						<h2 className="is-size-3 mb-2">Add New Delivery Zone</h2>
						<form onSubmit={addDeliveryZone}>
							<div className="mb-3">
								<label className="label">Zone Name</label>
								<input name="zone-name" value={zoneName} onChange={(e) => setZoneName(e.target?.value)} className="input" type="text" />
							</div>

							<label className="label">Fee (KD)</label>
							<div className="is-flex is-flex-direction-row">
								<input name="fee" value={fee} onChange={(e) => setFee(e.target?.value)} className="input" type="number" step="0.001" />
								<input type="submit" className="button-width ml-4 is-info button" name="Save Zone" />
							</div>
						</form>
					</div>
				</div>
				<button onClick={() => setAddingDeliveryZone(false)} className="modal-close is-large" aria-label="close"></button>
			</div>

			<div className={`modal ${zone.name ? 'is-active' : ''}`}>
				<div className="modal-background"></div>
				<div className="modal-content">
					<div className="box">
						<button onClick={(e) => {e.preventDefault(); setZone({}) } } className="is-pulled-right is-large is-clickable" aria-label="close">X</button>
						<h2 className="is-size-3 mb-2">Edit {zone.name}</h2>

						<form onSubmit={(e) => editPrimaryZone(e, zoneName, fee)}>
							<div className="mb-3">
								<label className="label">Zone Name</label>
								<input name="zone-name" value={zoneName} onChange={(e) => setZoneName(e.target?.value)} className="input" type="text" />
							</div>

							<label className="label">Fee (KD)</label>
							<div className="is-flex is-flex-direction-row">
								<input name="fee" value={fee} onChange={(e) => setFee(e.target?.value)} className="input" type="number" step="0.001" />
								<input type="submit" className="button-width ml-4 is-info button" name="Save Zone" />
							</div>
						</form>

						<hr />

						<form onSubmit={(e) => editZone(e, selectedCity, true)}>
		
							<label className="label">Add City</label>
							<div className="is-flex is-flex-direction-row">
								<SelectCity value={selectedCity} onSelect={(city) => setSelectedCity(city)} />
								<input type="submit" value="Add city" className="button-width ml-4 is-info button" name="Save Zone" />
							</div>
						</form>
						<hr />
						<h2 className="has-text-weight-bold">Cities Added</h2>
						{zone.cities 
							?	zone.cities.map(city => (
									<div key={city} className="is-flex py-2">
										<p>{city}</p>
										<button className="ml-4 is-clickable" onClick={(e) => editZone(e, city, false)}>X</button>
									</div>
								))
							: ''
						}
					</div>
				</div>
				<button onClick={() => setZone({})} className="modal-close is-large" aria-label="close"></button>
			</div>

			<button 
				onClick={() => setAddingDeliveryZone(true)}  
				className="button is-info is-pulled-right"
			>
				Add Delivery Zone +
			</button>
			<br />
			<br />

			<table className="table is-bordered is-striped is-fullwidth is-centered">
				<thead>
					<tr>
						<th>Delivery Zones</th>
						<th>Delivery Fees</th>
						<th>Settings</th>
					</tr>
				</thead>
				<tbody>
					{data.map(item => 
						<tr key={item.id}>
						  <td>{item.name}</td>
						  <td>{Math.abs(item.fee).toFixed(3)} KWD</td>
						  <td><button onClick={() => showZone(item)} className="link">Edit Zone</button></td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}