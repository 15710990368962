import { useUser } from '../../swr/index'

export default function DriverName({ driverId }) {

	const { data, isLoading, isError } = useUser(driverId) 

	if (isLoading) return 'Loading...'
	if (isError) return "Error..."

	return(	
		<span>{data.driver_name}</span>
	)
}