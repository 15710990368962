import { Outlet, useNavigate } from 'react-router-dom'
import { USER_ROLE } from '../../common/constants'
import { useEffect } from 'react'

export default function CheckAuth() {

	const navigation = useNavigate();

	useEffect(() => {
		if (localStorage.getItem('role') !== USER_ROLE.ADMIN) {
			navigation('/admin')
		}
	}, [])

	return (
		<div>
			<img className="m-4" width="90px" src={process.env.PUBLIC_URL + '/logo-purple.png'} /> 
			<div className="m-2">
				<Outlet />
			</div>
			<div className="mb-6 pb-6" />
		</div>
	)
}