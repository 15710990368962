import { useUser } from '../../swr/index'

export default function BusinessName({ userId }) {

	const { data, isLoading, isError } = useUser(userId) 

	if (isLoading) return 'Loading...'
	if (isError) return "Error..."

	return(	
		<span>{data.business_name}</span>
	)
}