import { useState, useEffect } from 'react'

export default function TimePicker({ onChange }) {

	const [hour, setHour] = useState('1')
	const [minutes, setMinutes] = useState('0')
	const [time, setTime] = useState('AM')

	useEffect(() => {
		const newTimeString = `${hour}:${minutes} ${time}`
		onChange(newTimeString)
	}, [hour, minutes, time])

	return(
		<div className="is-flex is-align-items-center">
			<select value={hour} onChange={(e) => setHour(e.target?.value)}  className="py-1 px-1 has-text-centered mr-1">
				<option value="1">1</option>
				<option value="2">2</option>
				<option value="3">3</option>
				<option value="4">4</option>
				<option value="5">5</option>
				<option value="6">6</option>
				<option value="7">7</option>
				<option value="8">8</option>
				<option value="9">9</option>
				<option value="10">10</option>
				<option value="11">11</option>
				<option value="12">12</option>
			</select>
			<p>:</p>
			<select value={minutes} onChange={(e) => setMinutes(e.target?.value)}  className="py-1 px-1 ml-1 mr-1">
				<option value="0">00</option>
				<option value="10">10</option>
				<option value="20">20</option>
				<option value="30">30</option>
				<option value="40">40</option>
				<option value="50">50</option>
			</select>

			<select value={time} onChange={(e) => setTime(e.target?.value)} className="py-1 px-1">
				<option value="AM">AM</option>
				<option value="PM">PM</option>
			</select>
		</div>
	)
}