export const syncLoading = (loadingHandler, loadingKey, action = 'end', successKey, successValue) => {
    const updatedLoadHandler = { ...loadingHandler };

    if (action === 'start') {
        updatedLoadHandler[loadingKey] = true;
    } else {
        updatedLoadHandler[loadingKey] = false;
        if (successKey && successValue !== undefined) {
            updatedLoadHandler[successKey] = successValue;
        }
    }
    return updatedLoadHandler;
};

const START = 'START';
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

export const makeHttpActionTypes = (reducer, action) => ({
    [action]: `${reducer}/${action}`,
    [`${action}_${START}`]: `${reducer}/${action}/${START}`,
    [`${action}_${SUCCESS}`]: `${reducer}/${action}/${SUCCESS}`,
    [`${action}_${FAIL}`]: `${reducer}/${action}/${FAIL}`
});

export const makeActionType = (reducer, action) => `${reducer}/${action}`;
