import { useBusinessUsers } from '../../swr/admin'

export default function SelectBusinessCompany({ onSelect, value = '' }) {
	const { businesses, isLoading, isError } = useBusinessUsers()

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	return (
		<div className="select is-fullwidth">

			<select value={value} onChange={(e) => onSelect(e.target.value)} name="business">
				<option value="">Select a business company</option>
				{businesses.map(user => 
					<option key={user.id} value={user.id}>
						{user.business_name}
					</option>
				)}
			</select>
		</div>
	)
}