import React, { useState } from 'react';
import styles from './styles/DisplayDeliveryZones.module.css';
import DisplayPricingTiers from './DisplayPricingTiers';
import DisplayZoneCities from './DisplayZoneCities';

const DisplayDeliveryZones = ({ pricingPlan }) => {
    const [expandedZones, setExpandedZones] = useState({});

    const toggleZoneDataVisibility = (zoneId) => {
        setExpandedZones(prevExpandedZones => ({
            ...prevExpandedZones,
            [zoneId]: !prevExpandedZones[zoneId],
        }));
    };

    return (
        <>
            {pricingPlan.delivery_zones && pricingPlan.delivery_zones.length > 0 && (
                pricingPlan.delivery_zones.map((zone) => (
                    <div key={zone.id}>
                        <div className={styles.divider_line}></div>
                        <div
                            className={`${styles.delivery_zone_clickable_box} ${expandedZones[zone.id] ? styles.expanded : ""}`}
                            onClick={() => toggleZoneDataVisibility(zone.id)}
                        >
                            <div className={styles.delivery_zone_name}>{zone.name}</div>
                            <span className={`${styles.arrow} ${expandedZones[zone.id] ? styles.up : styles.down}`}></span>
                        </div>
                        {expandedZones[zone.id] && (
                            <div className={styles.zone_details_box}>
                                <div className={styles.base_price_text}>Base Per Order Price: {zone.price_per_order} KWD</div>
                                <DisplayPricingTiers
                                    pricingPlan={pricingPlan}
                                    zone={zone}
                                />
                                <DisplayZoneCities
                                    cities={zone.cities}
                                />
                            </div>
                        )}
                    </div>
                ))
            )}
        </>
    );
};

export default DisplayDeliveryZones;