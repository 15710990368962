import { useTypeOfProducts } from '../../swr/admin'

export default function SelectTypeOfProduct({ onSelect, value = '' }) {
	const { data, isLoading, isError } = useTypeOfProducts()

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	return (
		<div className="select is-fullwidth">
			<select value={value} onChange={(e) => onSelect(e.target.value)} name="product">
				<option value="">Select a type of product</option>
				{data.map(product => <option key={product.name} value={product.name}>{product.name}</option>)}
			</select>
		</div>
	)
}