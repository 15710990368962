import useSWR from 'swr'
import { API_URL, GET_FETCH_OPTIONS } from '../common/constants'

const fetcher = ( url, query = '' ) => fetch(
 			`${url}${query}`, 
 			GET_FETCH_OPTIONS()
 		).then(res => { 
 			if (res.status === 401) {
 				window.location.href = '/?sessionExpired=true'
 			}
 			return res.json()
 		}) 


export function useOrders(page = 1, limit = 10, status = '', from = '', to = '', name = '', business = '') {

	let query = `?page=${page || 1}&limit=${limit}`
	if (status) {
		query = query + `&status=${status}`
	}
	if (from && to) {
		query = query + `&from=${from}&to=${to}`
	}
	if (name) {
		query = query + `&name=${name}`
	}
	if (business) {
		query = query + `&business=${business}`

	}

	const { data, error } = useSWR([
		API_URL.ORDERS, 
		query
		], fetcher)

	return {
		orders: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useOrder(id) {
	const { data, error } = useSWR(API_URL.ORDERS+`/${id}`, fetcher)

	return {
		order: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useResumeCurrentOrder() {
	const { data, error } = useSWR(API_URL.ORDERS_RESUME_ORDER, fetcher)

	return {
		order: data ? data.order : data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useOrdersTotal() {
	const { data, error } = useSWR(API_URL.ORDERS_TOTAL, fetcher)

	return {
		total: data ? data.total : data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useOrdersDriversHistory(page = 1, limit = 10, from = '', to = '') {
	let query = `?page=${page || 1}&limit=${limit}`

	if (from && to) {
		query = query + `&from=${from}&to=${to}`
	}

	const { data, error } = useSWR([
		API_URL.ORDERS_DRIVER_HISTORY,
		query
		], fetcher)

	return {
		orders: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useOrdersDriverTotal() {
	const { data, error } = useSWR(API_URL.ORDERS_DRIVER_TOTAL, fetcher)

	return {
		total: data ? data.total : data,
		isLoading: !error && !data,
		isError: error
	}
}


export function useOrderItems(orderId) {
	const { data, error } = useSWR(API_URL.ORDER_ITEMS +`/${orderId}`, fetcher)

	return {
		items: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useProductItems() {
	const { data, error } = useSWR(API_URL.PRODUCTS, fetcher)

	return {
		products: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function usePickupAddresses(page = 1, limit = 5) {
	const { data, error } = useSWR([
		API_URL.PICKUP_ADDRESSES,
		`?page=${page}&limit=${limit}`
	], fetcher)

	return {
		pickupAddresses: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function usePickupAddressesTotal() {
	const { data, error } = useSWR(API_URL.PICKUP_ADDRESSES_TOTAL, fetcher)

	return {
		total: data ? data.total : data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useAddress(id) {
	const { data, error } = useSWR(API_URL.PICKUP_ADDRESSES + `/${id}`, fetcher)

	return {
		address: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useCurrentUser() {
	const { data, error } = useSWR(API_URL.CURRENT_USER, fetcher)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useUser(id) {
	const { data, error } = useSWR(API_URL.CURRENT_USER + `?id=${id}`, fetcher)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}