import axios from 'axios'; // Import axios
//import User from '../../'

const shopifyConnectionHandler = async (shop) => {
    try {

        const installURL = process.env.REACT_APP_SHOPIFY_INSTALL_URL;
        const token = localStorage.getItem('token'); // Retrieve the token
        const email = localStorage.getItem('userEmail');

        const response = await axios.post(`${installURL}`,
            {
                email,
                shop
            },
            {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        const { url } = response.data.data;

        // Popup window dimensions
        const width = 1000;
        const height = 600;

        // Calculate the position to center the popup
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.outerHeight / 2) - (height / 2);

        // Open the URL in a centered popup window
        window.open(url, 'Shopify OAuth', `width=${width},height=${height},top=${top},left=${left}`);

    } catch (error) {
        console.error('Error connecting to Shopify:', error);
    }
};

export default shopifyConnectionHandler; // Export the function