import {all} from 'redux-saga/effects';

import pricingPlansSaga from '../components/pricingPlan/redux/saga';
import packageSelectionSaga from '../components/packageSelection/redux/saga';

export default function* rootSaga() {
    yield all([
        pricingPlansSaga(),
        packageSelectionSaga()
    ]);
}
