import { useOrderItems } from '../../swr/index'

export default function OrderItems({ orderId }) {

	const { items, isLoading, isError } = useOrderItems(orderId)

	if (isLoading) return 'Loading...'
	if (isError) return "Error..."

	return(	
		<div className="card p-4">
			<h1 className="is-size-4 has-text-centered has-text-weight-bold mb-2">Order Items</h1>
			<table className="table mx-auto is-bordered is-fullwidth">
			  <thead>
			    <tr>
			      <th>Product Name</th>
			      <th>Price</th>	  
			      <th>Quantity</th>
			      <th>Total</th> 
			    </tr>
			  </thead>
			  <tbody>
					{items.map(item => (
						<tr key={item.id}>
							<td>{item.name}</td>
							<td>{Math.abs(item.price).toFixed(3)} KWD</td>
							<td>{item.quantity}</td>
							<td>{Math.abs(item.price * item.quantity).toFixed(3)} KWD</td>
						</tr>
					))}		      
			  </tbody>
			</table>
		</div>
	)
}