import { Link } from 'react-router-dom'

export default function AdminFooterNavigation({ activeTab }) {
	return(
		<div className="tabs is-toggle is-fullwidth">
		  <ul>
		    <li 
		    	className={activeTab === 'admin-dashboard' ? 'is-active' : ''}
		    >
		    	<Link to="/admin/dashboard">Order history</Link>
		    </li>
		    <li 
		    	className={activeTab === 'admin-businesses' ? 'is-active' : ''}
		    >
		    	<Link to="/admin/businesses">Businesses</Link>
		    </li>
		    <li className={activeTab === 'admin-tracking' ? 'is-active' : ''}>
		    	<Link to="/admin/tracking">Realtime Bookings & Tracking</Link>
		    </li>
		    <li className={activeTab === 'admin-fees' ? 'is-active' : ''}>
		    	<Link to="/admin/fees">Delivery Fees</Link>
		    </li>
		    <li className={activeTab === 'admin-settings' ? 'is-active' : ''}>
		    	<Link to="/admin/settings">Settings</Link>
		    </li>
		  </ul>
		</div>
	)
}