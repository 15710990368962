import './style.css';
import { Link } from 'react-router-dom';
import FooterNavigation from '../../components/FooterNavigation'
import Pagination from '../../components/small/Pagination'
import { useOrders, useOrdersTotal } from '../../swr/index'
import { useState } from 'react'
import { PAYMENT_METHOD_TITLE, DELIVERY_STATUS } from '../../common/constants'
import DeliveryStatusFormat from '../../components/UI/DeliveryStatusFormat'
import moment from 'moment'

function Table({ page, limit, from, to }) {

  const { orders, isLoading, isError } = useOrders(page, limit, '', from, to)

  if (isLoading) return 'Loading...'
  if (isError) return 'Error...'

  const subtotalSum = orders.reduce((sum, order) => sum + parseFloat(order.status !== DELIVERY_STATUS.CANCELED ? order.subtotal : 0), 0)
  const deliverySum = orders.reduce((sum, order) => sum + parseFloat(order.status !== DELIVERY_STATUS.CANCELED ? order.delivery_fee : 0), 0)
  return(
    <div className="table-container">
      <table className="table mx-auto is-bordered is-fullwidth">
        <thead>
          <tr>
            <th>Invoice #</th>
            <th>Order Details</th>
            <th>Customer Name</th>
            <th>Customer phone number</th>
            <th>Customer Payment Method</th>
            <th>Subtotal</th>
            <th>Delivery Fees</th>
            <th>Total</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td><Link to={`/order/${order.id}`}>{order.invoice_number}</Link></td>
              <td>{order.item_ids ? order.item_ids.length : 0} items</td>
              <td>{order.customer_name}</td>
              <td>{order.customer_phone_number}</td>
              <td>{PAYMENT_METHOD_TITLE[order.payment_method]}</td>
              <td>{Math.abs(order.subtotal).toFixed(3)} KWD</td>
              <td>{Math.abs(order.delivery_fee).toFixed(3) || 0} KWD</td>
              <td>{Math.abs(parseFloat(order.delivery_fee) + parseFloat(order.subtotal)).toFixed(3)} KD</td>
              <td align="center"><DeliveryStatusFormat status={order.status} /></td>
            </tr>
          ))}
            <tr key="final-totals">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="has-background-grey-light has-text-weight-bold">Total</td>
              <td className="has-background-grey-light has-text-weight-bold">{Math.abs(subtotalSum).toFixed(3)} KWD</td>
              <td className="has-background-grey-light has-text-weight-bold">{Math.abs(deliverySum).toFixed(3)} KWD</td>
              <td className="has-background-grey-light has-text-weight-bold">{Math.abs(subtotalSum + deliverySum).toFixed(3)} KWD</td>
              <td align="center"></td>
            </tr>
        </tbody>
      </table>
    </div>
  )
}

export default function DashboardPage() {

  const limit = 50;
  const { total, isLoading, isError } = useOrdersTotal()

  const [currentPage, setCurrentPage] = useState(1)

  const [selectedFromDate, setSelectedFromDate] = useState('')
  const [selectedToDate, setSelectedToDate] = useState('')

  if (isLoading) return 'Loading...'
  if (isError) return 'Error...'

  const today = moment().format('YYYY-MM-DD')

  const clearSearch = () => {
    setSelectedFromDate('')
    setSelectedToDate('')
  }

  return (
    <div className="container">
      <h1 className="is-size-1 has-text-centered my-5">Orders Tracking</h1>
      <FooterNavigation activeTab={'dashboard'} />
      <div className="my-6" />
      <div className="my-2">
        <span>From: <input value={selectedFromDate} onChange={(e) => setSelectedFromDate(e.target?.value)} className="mr-2"  max={today} type='date' /></span>
        {selectedFromDate && <span>To: <input value={selectedToDate} onChange={(e) => setSelectedToDate(e.target?.value)} className="mr-2" type='date' min={selectedFromDate} max={today} /></span>}
        {(selectedFromDate && selectedToDate) && <button onClick={() => clearSearch()}>Reset</button>}
      </div>
      <Table page={currentPage} limit={limit} from={selectedFromDate} to={selectedToDate} />
      {(!selectedFromDate && !selectedToDate) && <Pagination currentPage={currentPage} setPage={setCurrentPage} totalPages={Math.ceil(total / limit)} />}
    </div>
  );
}
