import { useState } from 'react'
import { API_URL, POST_FETCH_OPTIONS } from '../../common/constants'

export default function ForgotPasswordPage({ }) {

	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [email, setEmail] = useState('')

	const resetPassword = (e) => {
		e.preventDefault();
		setSuccessMessage('')
		setErrorMessage('')
		if (email) {
			fetch(API_URL.RESET_PASSWORD, POST_FETCH_OPTIONS({ email }))
				.then(res => res.json())
				.then(data => {
					data.success ? setSuccessMessage('Check your email for reset instructions.') : setErrorMessage(data.message || 'Something went wrong. Please try again.')
				})
				.catch(err => {
					console.log(err)
				})	
		}
	}

	return(
		<div className="center">
			<div className="max-width-md mx-auto">
				<h1 className="is-size-1 has-text-centered my-4">Forgot Password?</h1>
				<form onSubmit={resetPassword}>
					<label className="label">Email</label>
					<input name="email" onChange={(e) => setEmail(e.target.value)} className="input mb-4" type="email" />
					<input className="has-background-grey-light button is-fullwidth mt-2" type="submit" value="Reset Password" />
				</form>
				<div className="my-3" />
				{successMessage && <p className="has-text-centered has-text-info">{successMessage}</p>}
				{errorMessage && <p className="has-text-centered has-text-danger">{errorMessage}</p>}
			</div>
		</div>
	)
}