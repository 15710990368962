import { Link } from 'react-router-dom'
import AdminFooterNavigation from '../../components/AdminFooterNavigation'
import  { useOrders, useOrdersTotal } from '../../swr/index'
import DriverDeliveryCompany from '../../components/small/DriverDeliveryCompany'
import DriverName from '../../components/small/DriverName'
import BusinessName from '../../components/small/BusinessName'
import Pagination from '../../components/small/Pagination'
import { useState } from 'react'
import { DELIVERY_STATUS_COLOR, PAYMENT_METHOD_TITLE, DELIVERY_STATUS } from '../../common/constants'
import StatusPicker from '../../components/UI/StatusPicker'
import moment from 'moment'
import SelectDeliveryCompany from '../../components/small/SelectDeliveryCompany'
import SelectBusinessCompany from '../../components/small/SelectBusinessCompany'


function TableBody({ page, limit, status, from, to, name, business }) {
	const { orders, isLoading, isError } = useOrders(page, limit, status, from, to, name, business)

	if (isLoading) return <tbody></tbody>
	if (isError) return <tbody></tbody>

	const subtotalSum = orders.reduce((sum, order) => sum + parseFloat((order.status !== DELIVERY_STATUS.CANCELED && order.status !== DELIVERY_STATUS.FAILED) ? order.subtotal : 0), 0)
	const deliverySum = orders.reduce((sum, order) => sum + parseFloat((order.status !== DELIVERY_STATUS.CANCELED && order.status !== DELIVERY_STATUS.FAILED) ? order.delivery_fee : 0), 0)
	
	return(
		<tbody>
			{orders.map(order => (
			  <tr key={order.id}>
			  	<td>{order.id}</td>
			    <td><Link to={`/order/${order.id}`}>{order.invoice_number}</Link></td>
			    <td>{order.item_ids ? order.item_ids.length : 0} items</td>
			    <td><BusinessName userId={order.user_id} /></td>
			    <td><DriverName driverId={order.driver_id} /></td>
			    <td><DriverDeliveryCompany driverId={order.driver_id} /></td>
			    <td>{PAYMENT_METHOD_TITLE[order.payment_method]}</td>
			    <td>{Math.abs(order.subtotal).toFixed(3)} KWD</td>
			    <td>{Math.abs(order.delivery_fee).toFixed(3) || 0} KWD</td>
			    <td>{Math.abs(parseFloat(order.delivery_fee) + parseFloat(order.subtotal)).toFixed(3)} KD</td>
			    <td align="center" style={{ color: DELIVERY_STATUS_COLOR[order.status] }}>{order.status}</td>
			  </tr>
			))}
				<tr key="final-totals">
				  <td></td>
				  <td></td>
				  <td></td>
				  <td></td>
				  <td></td>
				  <td></td>
				  <td className="has-background-grey-light has-text-weight-bold">Total</td>
				  <td className="has-background-grey-light has-text-weight-bold">{Math.abs(subtotalSum).toFixed(3)} KWD</td>
				  <td className="has-background-grey-light has-text-weight-bold">{Math.abs(deliverySum).toFixed(3)} KWD</td>
				  <td className="has-background-grey-light has-text-weight-bold">{Math.abs(subtotalSum + deliverySum).toFixed(3)} KWD</td>
				  <td align="center"></td>
				</tr>
		</tbody>
	)
}

function Table({ page, limit, from, to, name, business }) {

	const [searchStatus, setSearchStatus] = useState('')

	return(
		<div className="table-container">
			<table className="table is-bordered is-fullwidth">
				<thead>
					<tr>
						<td>Internal Id</td>
						<th>#</th>
						<th>Order Details</th>
						<th>Business name</th>
						<th>Driver Name</th>
						<th>Delivery Company</th>
						<th>Customer Payment Method</th>
						<th>Subtotal</th>
						<th>Delivery Fees</th>
						<th>Total</th>
						<th>
							<span>Status</span>
							<StatusPicker selected={searchStatus} setStatus={setSearchStatus} />
						</th>
					</tr>
				</thead>
				<TableBody page={page} limit={limit} status={searchStatus} from={from} to={to} name={name} business={business} />
			</table>
		</div>
	)
}

export default function AdminDashboardPage() {

	const limit = 50;
	const { total, isLoading, isError } = useOrdersTotal()
	const [currentPage, setCurrentPage] = useState(1)

	const [selectedFromDate, setSelectedFromDate] = useState('')
	const [selectedToDate, setSelectedToDate] = useState('')
	const [queryName, setQueryName] = useState('')
	const [businessName, setBusinessName] = useState('')

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const today = moment().format('YYYY-MM-DD')

	const clearSearch = () => {
		setSelectedFromDate('')
		setSelectedToDate('')
	}

	return (
		<div className="container">
			<h1 className="is-size-1 has-text-centered my-5">Dashboard</h1>
			<AdminFooterNavigation activeTab={'admin-dashboard'} />
			<div className="my-6" />
			<div className="my-2">
				<span>From: <input value={selectedFromDate} onChange={(e) => setSelectedFromDate(e.target?.value)} className="mr-2"  max={today} type='date' /></span>
				{selectedFromDate && <span>To: <input value={selectedToDate} onChange={(e) => setSelectedToDate(e.target?.value)} className="mr-2" type='date' min={selectedFromDate} max={today} /></span>}
				{(selectedFromDate && selectedToDate) && <button onClick={() => clearSearch()}>Reset</button>}
				<div className="my-2"><SelectDeliveryCompany value={queryName} onSelect={setQueryName}  /></div>
				<div className="my-2"><SelectBusinessCompany value={businessName} onSelect={setBusinessName} /></div>
			</div>	

			<Table page={currentPage} limit={limit} from={selectedFromDate} to={selectedToDate} name={queryName} business={businessName} />
			{(!selectedFromDate && !selectedToDate) && <Pagination currentPage={currentPage} setPage={setCurrentPage} totalPages={Math.ceil(total / limit)} />}
		</div>
	)
}
