import React, { useEffect, useState } from 'react';
import storage from '../../../utils/storage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCities, createPricingPlan } from '../redux/actions';
import CreatePricingPlanOverview from './CreatePricingPlanOverview';
import { cityListSelector } from '../redux/selectors';
import { useNavigate } from 'react-router-dom';

const CreatePricingPlan = () => {
  const dispatch = useDispatch();
  const { cities, getCitiesLoading } = useSelector(cityListSelector, shallowEqual);
  const { loadingHandler } = useSelector(state => state.pricingPlanReducer, shallowEqual);
  const { create_pricing_plan_loading, success } = loadingHandler;
  const [hasCreated, setHasCreated] = useState(false);
  const navigate = useNavigate();

  const navigateToListPricingPlanPage = () => {
    navigate('/driver/pricingPlans');
  };

  useEffect(() => {
    if (hasCreated && !create_pricing_plan_loading) {
      if (success === 'true') {
        navigateToListPricingPlanPage();
      }
    }
  }, [create_pricing_plan_loading, success, navigateToListPricingPlanPage]);


  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);


  const createPricingPlanHandler = (payload) => {
    const deliveryCompanyId = storage.get('deliveryCompanyId');
    dispatch(createPricingPlan({ deliveryCompanyId, payload }));
    setHasCreated(true);
  };

  return (
    <CreatePricingPlanOverview
      createPricingPlanHandler={createPricingPlanHandler}
      cities={cities}
      getCitiesLoading={getCitiesLoading}
      navigateToListPricingPlanPage={navigateToListPricingPlanPage}
    />
  );
};

export default CreatePricingPlan;