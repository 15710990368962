import React from 'react';
import styles from '../../common/styles/Table.module.css'; // Updated import

const DisplayPricingTiers = ({ pricingPlan, zone }) => {
    const sortedTiers = [...zone.pricing_tiers].sort((a, b) => a.minimum_orders - b.minimum_orders);
    return (
        <div>
            {sortedTiers && sortedTiers.length > 0 && (
                <div className={styles.table_box}>
                    <table className={styles.data_table}>
                        <thead>
                            <tr>
                                <th>Order Range</th>
                                <th>Price</th>
                                <th>Period</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedTiers.map((tier, tierIndex) => (
                                <tr key={tierIndex}>
                                    <td>{tier.minimum_orders}-{tier.maximum_orders}</td>
                                    <td>{tier.price} KWD</td>
                                    <td>{pricingPlan.period.charAt(0).toUpperCase() + pricingPlan.period.slice(1)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
};

export default DisplayPricingTiers;