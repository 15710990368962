import { useUser } from '../../swr/index'

export default function DriverDeliveryCompany({ driverId }) {

	const { data, isLoading, isError } = useUser(driverId) 

	if (isLoading) return 'Loading...'
	if (isError) return "Error..."

	return(	
		<span>{data.delivery_company ? data.delivery_company.name : ''}</span>
	)
}