
import { useCitiesList } from '../swr/admin'
import { useState } from 'react'
import { API_URL, POST_FETCH_OPTIONS } from '../common/constants'
import { useSWRConfig } from 'swr'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DeliveryCompanies() {

	const { mutate } = useSWRConfig()
	const { data, isLoading, isError } = useCitiesList()
	
	const [addingCity, setAddingCity] = useState(false)
	const [cityName, setCityName] = useState('')
	const [selectedOption, setSelectedOption] = useState('')
	const [showEmptyGovernorate, setShowEmptyGovernorate] = useState(false)
	const [governorate, setGovernorate] = useState('')

	if (isLoading) return 'Loading...'
	if (isError) return 'Error...'

	const addCity = (e) => {
		e.preventDefault();

		if (!cityName || !governorate) {
			return
		}

		mutate(API_URL.CITIES, async data => {
			const newCity = await fetch(API_URL.CITIES, POST_FETCH_OPTIONS({
				name: cityName,
				governorate: governorate,
			})).then(res => res.json())

			setCityName('')
			setGovernorate('')
			setAddingCity(false)
			return [...data, newCity]
		})
		toast.info('City added / re-enabled')
	}

	const disableCity = (city) => {

		mutate(API_URL.CITIES, async data => {
			const newCity = await fetch(API_URL.CITIES, POST_FETCH_OPTIONS({
				name: city.name,
				governorate: city.governorate,
				disable: true
			})).then(res => res.json())

			return [...data]
		})
		toast.info('City disabled.')
	}

	const onSelect = (value) => {
		if (value === 'OTHER') {
			setShowEmptyGovernorate(true)
			setGovernorate('')
		} else {
			setShowEmptyGovernorate(false)
			setGovernorate(value)
		}
		setSelectedOption(value)

	}

	const governorates = [...new Set(data.map(item => item.governorate))];

	return (
		<div>
			<div className={`modal ${addingCity ? 'is-active' : ''}`}>
				<div className="modal-background"></div>
				<div className="modal-content">
					<div className="box">
						<button onClick={(e) => {e.preventDefault(); setAddingCity(false) } } className="is-pulled-right is-large is-clickable" aria-label="close">X</button>
						<h2 className="is-size-3 mb-2">Add City</h2>
						<form onSubmit={addCity}>
							<label className="label">City Name</label>
							<input 
								onChange={(e) => setCityName(e.target?.value)} 
								className="input mb-3" 
								value={cityName} 
								placeholder="City Name" 
								type="text" 
								name="name" 
							/>

							<label className="label">Governorate</label>
							<select className="select is-fullwidth" value={selectedOption} onChange={(e) => onSelect(e.target.value)} name="governorate">
								{governorates.map(governorate => (
									<option key={governorate} value={governorate}>{governorate}</option>
								))}
								<option value="OTHER">Other</option>
							</select>
							{showEmptyGovernorate &&
								<input 
									onChange={(e) => setGovernorate(e.target?.value)} 
									className="input my-2" 
									value={governorate} 
									placeholder="Governorate Name" 
									type="text" 
									name="governorate" 
								/>
							}
							<input type="submit" className="button-width mt-4 is-fullwidth is-info button" name="Save City" />
						</form>
					</div>
					<button onClick={() => setAddingCity(false)} className="modal-close is-large" aria-label="close"></button>
				</div>
			</div>
			<button 
				onClick={() => setAddingCity(true)}  
				className="button is-info is-pulled-right"
			>
				Add City +
			</button>
			<br />
			<br />

			<table className="table is-bordered is-striped is-fullwidth is-centered">
				<thead>
					<tr>
						<th>City</th>
						<th>Governorate</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{data.map(item => 
						<tr key={item.name}>
						  <td>{item.name}</td>
						  <td>{item.governorate}</td>
						  <td><button onClick={() => disableCity(item)} className="link">Disable</button></td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}